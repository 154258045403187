import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { BarChartLine, XCircle } from 'react-bootstrap-icons'
import produce from 'immer'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import './NotificationsTableSearch.css'
import DateFilter from '../common/DateFilter'
import { useParams } from 'react-router-dom'
import Loading from '../common/Loading'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const NotificationsTableSearch = (props) => {
  const {
    appliedSearchFilters,
    setAppliedSearchFilters,
    searchText,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    setShowNotificationChart,
  } = props
  const { contact } = useParams()
  const user = useReactiveVar(loggedInUserVar)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRecordType, setSelectedRecordType] = useState()
  const [searchFilters, setSearchFilters] = useState([])
  const [hasMoreSearchFilters, setHasMoreSearchFilters] = useState(true)
  const [jobsCursor, setJobsCursor] = useState()
  const [employeesCursor, setEmployeesCursor] = useState()
  const [subjectGroupsCursor, setSubjectGroupsCursor] = useState()
  const [organizationsCursor, setOrganizationsCursor] = useState()
  const [subjectsCursor, setSubjectsCursor] = useState()

  const CONTACT_QUERY = gql`
    query NotificationsJobsQueries(
      $jobsCursor: String
      $subjectGroupsCursor: String
      $organizationsCursor: String
      $subjectsCursor: String
      $searchTerm: String
      $includeJobs: Boolean!
      $includeSubjects: Boolean!
      $includeSubjectGroups: Boolean!
      $includeOrganizations: Boolean!
    ) {
      jobs(
        first: 10
        after: $jobsCursor
        name_Icontains: $searchTerm
        contact: true
      ) @include(if: $includeJobs) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
          }
        }
      }
      subjects(
        first: 10
        after: $subjectsCursor
        search: $searchTerm
        contact: true
      ) @include(if: $includeSubjects) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      subjectGroups(
        first: 10
        after: $subjectGroupsCursor
        subjectGroupOrganizationName: $searchTerm
        contact: true
      ) @include(if: $includeSubjectGroups) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
          }
        }
      }
      organizations(
        first: 10
        after: $organizationsCursor
        name_Icontains: $searchTerm
        contact: true
      ) @include(if: $includeOrganizations) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
          }
        }
      }
    }
  `

  const NON_CONTACT_QUERY = gql`
    query NotificationsJobsQueries(
      $jobsCursor: String
      $employeesCursor: String
      $subjectGroupsCursor: String
      $organizationsCursor: String
      $subjectsCursor: String
      $searchTerm: String
      $includeJobs: Boolean!
      $includeEmployees: Boolean!
      $includeSubjects: Boolean!
      $includeSubjectGroups: Boolean!
      $includeOrganizations: Boolean!
    ) {
      jobs(first: 10, after: $jobsCursor, name_Icontains: $searchTerm)
        @include(if: $includeJobs) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
          }
        }
      }
      employees(first: 10, after: $employeesCursor, search: $searchTerm)
        @include(if: $includeEmployees) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      subjects(first: 10, after: $subjectsCursor, search: $searchTerm)
        @include(if: $includeSubjects) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              id
              firstName
              lastName
            }
          }
        }
      }
      subjectGroups(
        first: 10
        after: $subjectGroupsCursor
        subjectGroupOrganizationName: $searchTerm
      ) @include(if: $includeSubjectGroups) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
          }
        }
      }
      organizations(
        first: 10
        after: $organizationsCursor
        name_Icontains: $searchTerm
      ) @include(if: $includeOrganizations) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
          }
        }
      }
    }
  `

  const [
    jobFiltersQuery,
    { data: jobFiltersData, loading, fetchMore: jobFiltersFetchMore },
  ] = useLazyQuery(contact ? CONTACT_QUERY : NON_CONTACT_QUERY, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (jobFiltersData) {
      handleQueryResults(jobFiltersData)
    }
  }, [jobFiltersData])

  useEffect(() => {
    setSearchFilters([])
    if (searchTerm.length > 0 && selectedRecordType) {
      const variables = {
        searchTerm,
        jobsCursor,
        organizationsCursor,
        subjectsCursor,
        subjectGroupsCursor,
        includeJobs: selectedRecordType === 'Job',
        includeEmployees: selectedRecordType === 'Employee',
        includeSubjectGroups: selectedRecordType === 'Subject Group',
        includeSubjects: selectedRecordType === 'Subject',
        includeOrganizations: selectedRecordType === 'Organization',
      }
      if (!contact) {
        variables.employeesCursor = employeesCursor
      }
      jobFiltersQuery({
        variables,
      })
    }
  }, [searchTerm, selectedRecordType])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setEmployeesCursor()
    setJobsCursor()
    setSubjectGroupsCursor()
    setSubjectsCursor()
    setOrganizationsCursor()
    setSearchTerm(currentSearchTerm)
  }

  const fetchMoreSearchTerms = () => {
    const variables = {
      jobsCursor,
      organizationsCursor,
      subjectsCursor,
      subjectGroupsCursor,
      includeJobs: selectedRecordType === 'Job',
      includeEmployees: selectedRecordType === 'Employee',
      includeSubjectGroups: selectedRecordType === 'Subject Group',
      includeSubjects: selectedRecordType === 'Subject',
      includeOrganizations: selectedRecordType === 'Organization',
    }
    if (!contact) {
      variables.employeesCursor = employeesCursor
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    jobFiltersFetchMore({
      variables,
    })
  }

  const handleQueryResults = (queryResults, fromFetchMore) => {
    if (searchTerm.length === 0) {
      setSearchFilters([])
    } else {
      if (!contact && queryResults.employees?.pageInfo.endCursor) {
        setEmployeesCursor(queryResults.employees.pageInfo.endCursor)
      }
      if (queryResults.jobs?.pageInfo.endCursor) {
        setJobsCursor(queryResults.jobs.pageInfo.endCursor)
      }
      if (queryResults.subjectGroups?.pageInfo.endCursor) {
        setSubjectGroupsCursor(queryResults.subjectGroups.pageInfo.endCursor)
      }
      if (queryResults.organizations?.pageInfo.endCursor) {
        setOrganizationsCursor(queryResults.organizations.pageInfo.endCursor)
      }
      if (queryResults.subjects?.pageInfo.endCursor) {
        setSubjectsCursor(queryResults.subjects.pageInfo.endCursor)
      }

      if (
        (!contact && queryResults.employees?.pageInfo.hasNextPage) ||
        queryResults.jobs?.pageInfo.hasNextPage ||
        queryResults.subjectGroups?.pageInfo.hasNextPage ||
        queryResults.organizations?.pageInfo.hasNextPage ||
        queryResults.subjects?.pageInfo.hasNextPage
      ) {
        setHasMoreSearchFilters(true)
      } else {
        setHasMoreSearchFilters(false)
      }
      const currentSearchFilters = []
      const appliedSearchFilterIds = Object.keys(appliedSearchFilters)
      queryResults.subjects?.edges.forEach((subject) => {
        const subjectNode = subject.node
        if (!appliedSearchFilterIds.includes(subjectNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Subject',
            id: subjectNode.id,
            name: `${subjectNode.gaiaUser.firstName} ${subjectNode.gaiaUser.lastName}`,
          })
        }
      })
      if (!contact) {
        queryResults.employees?.edges.forEach((employee) => {
          const employeeNode = employee.node
          if (!appliedSearchFilterIds.includes(employeeNode.id)) {
            currentSearchFilters.push({
              nodeType: 'Employee',
              id: employeeNode.id,
              name: `${employeeNode.gaiaUser.firstName} ${employeeNode.gaiaUser.lastName}`,
            })
          }
        })
      }
      queryResults.jobs?.edges.forEach((job) => {
        const jobNode = job.node
        if (!appliedSearchFilterIds.includes(jobNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Job',
            id: jobNode.id,
            name: jobNode.name,
          })
        }
      })
      queryResults.subjectGroups?.edges.forEach((subjectGroup) => {
        const subjectGroupNode = subjectGroup.node
        if (!appliedSearchFilterIds.includes(subjectGroupNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Subject Group',
            id: subjectGroupNode.id,
            name: subjectGroupNode.name,
          })
        }
      })
      queryResults.organizations?.edges.forEach((organization) => {
        const organizationNode = organization.node
        if (!appliedSearchFilterIds.includes(organizationNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Organization',
            id: organizationNode.id,
            name: organizationNode.name,
          })
        }
      })
      if (fromFetchMore) {
        const nextSearchFilters = produce(searchFilters, (draftState) =>
          draftState.concat(currentSearchFilters)
        )
        setSearchFilters(nextSearchFilters)
      } else {
        const nextSearchFilters = produce(searchFilters, (draftState) => {
          return currentSearchFilters
        })
        setSearchFilters(nextSearchFilters)
      }
    }
  }

  const handleSearchFilterClick = (searchFilterRow) => {
    const tableRow = document.getElementById(`tableRow${searchFilterRow.id}`)
    const nodeId = searchFilterRow.original.id
    if (Object.keys(appliedSearchFilters).includes(nodeId)) {
      tableRow.classList.remove('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        delete prevSearchFilters[nodeId]
        return prevSearchFilters
      })
    } else {
      tableRow.classList.add('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        prevSearchFilters[searchFilterRow.original.id] =
          searchFilterRow.original
        return prevSearchFilters
      })
    }
  }

  const handleRemoveSearchFilterClick = (searchFilter) => {
    setAppliedSearchFilters((prevState) => {
      const prevSearchFilters = { ...prevState }
      delete prevSearchFilters[searchFilter[0]]
      return prevSearchFilters
    })
    if (searchFilters.length > 0) {
      const nextSearchFilters = produce(searchFilters, (draftState) => {
        draftState.push(searchFilter[1])
      })
      setSearchFilters(nextSearchFilters)
    }
  }

  const handleControlBlur = () => {
    setSearchTerm('')
  }

  const recordTypeOptions = [
    'Job',
    'Employee',
    'Subject',
    'Subject Group',
    'Organization',
  ]
  if (contact) {
    recordTypeOptions.splice(1, 1)
  }
  return (
    <>
      <div>
        <Row>
          <Col md={2}>
            <select
              className="form-control-sm form-select"
              name="recordType"
              onChange={(e) => {
                setSearchTerm('')
                setSelectedRecordType(e.target.value || undefined)
              }}
              value={selectedRecordType}
            >
              <option value="">Select Record Type</option>
              {recordTypeOptions.map((recordType) => (
                <option key={recordType} value={recordType}>
                  {recordType}
                </option>
              ))}
            </select>
          </Col>
          <Col xs={12} md={4}>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              disabled={!selectedRecordType}
              onBlur={handleControlBlur}
              onChange={handleSearchTermChange}
            />
          </Col>
          <Col
            style={{ marginTop: '-8px' }}
            md={6}
            className="d-flex align-items-center"
          >
            <DateFilter
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              placeholderStart="Sent From"
              placeholderEnd="Sent Until"
            />
            {user.permissions.isEmployee && (
              <Button
                className="ml-5"
                variant="link"
                onClick={() => {
                  setShowNotificationChart(true)
                }}
              >
                <BarChartLine className="mr-2" />
                Report
              </Button>
            )}
          </Col>
        </Row>
        {loading && (
          <div id="globalSearchInfiniteTable" className="border">
            <Loading height={'25'} width={'25'} />
          </div>
        )}
        {searchFilters.length > 0 ? (
          <>
            <div className="border">
              <Row style={{ position: 'relative', marginTop: '0px' }}>
                <Col
                  md={{ span: 4, offset: 2 }}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    zIndex: 1050,
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor: 'white',
                  }}
                >
                  <SortableInfiniteTable
                    hideGlobalFilter
                    displayHeader={false}
                    tableData={searchFilters}
                    tableColumns={[
                      {
                        Header: 'Sort Name',
                        accessor: 'name',
                      },
                    ]}
                    tableHeight={200}
                    fetchMoreTableData={fetchMoreSearchTerms}
                    hasMoreTableData={hasMoreSearchFilters}
                    onRowClick={handleSearchFilterClick}
                    rowPointer
                    tableSize="lg"
                  />
                </Col>
              </Row>
            </div>
          </>
        ) : null}

        <Row>
          <Col md={12}>
            {Object.keys(appliedSearchFilters).length > 0 ? (
              <div>
                {Object.entries(appliedSearchFilters).map(
                  (appliedSearchFilter, index) => {
                    return (
                      <div
                        key={appliedSearchFilter[0]}
                        className="d-inline-block mt-2"
                      >
                        <small className="bg-secondary rounded text-white mr-2 p-1 d-flex align-items-center">
                          <XCircle
                            className="mr-2"
                            onClick={() =>
                              handleRemoveSearchFilterClick(appliedSearchFilter)
                            }
                          />
                          <span>{`${appliedSearchFilter[1].name}`} | </span>
                          <span className="ml-1">
                            <small>{appliedSearchFilter[1].nodeType}</small>
                          </span>
                        </small>
                      </div>
                    )
                  }
                )}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NotificationsTableSearch
