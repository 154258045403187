import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Col, Form, Row, Dropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import './GlobalSearch.css'
import { Dot, Search, Robot } from 'react-bootstrap-icons'
import JobDetailModal from '../schedule/JobDetailModal'
import ContactModal from '../organization/ContactModal'
import LocationModal from '../location/LocationModal'
import AdminSessionDetailModal from '../sessions/AdminSessionDetailModal'
import Loading from '../common/Loading'
import ChatGPTModal from '../ChatGPTModal'
import { useReactiveVar } from '@apollo/client'
import { settingsVar } from '../../libs/apollo'

const GlobalSearch = () => {
  const [jobDetailId, setJobDetailId] = useState()
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [contactId, setContactId] = useState()
  const [location, setLocation] = useState()
  const [sessionId, setSessionId] = useState()
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [showContactModal, setShowContactModal] = useState()
  const [showLocationModal, setShowLocationModal] = useState()
  const [showSessionDetailModal, setShowSessionDetailModal] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [hasMoreSearchFilters, setHasMoreSearchFilters] = useState(true)
  const [jobsCursor, setJobsCursor] = useState()
  const [locationsCursor, setLocationsCursor] = useState()
  const [contactsCursor, setContactsCursor] = useState()
  const [employeesCursor, setEmployeesCursor] = useState()
  const [subjectGroupsCursor, setSubjectGroupsCursor] = useState()
  const [organizationsCursor, setOrganizationsCursor] = useState()
  const [subjectsCursor, setSubjectsCursor] = useState()
  const [sessionCursor, setSessionCursor] = useState()
  const [selectedRecordType, setSelectedRecordType] = useState()
  const history = useHistory()
  const settings = useReactiveVar(settingsVar)

  const [showChatGPTModal, setShowChatGPTModal] = useState(false)

  const toggleChatGPTModal = () => {
    setShowChatGPTModal(!showChatGPTModal)
  }

  const [
    globalSearchQuery,
    {
      data: globalSearchQueryData,
      loading,
      fetchMore: globalSearchQueryFetchMore,
    },
  ] = useLazyQuery(
    gql`
      query GlobalSearchQuery(
        $jobsCursor: String
        $employeesCursor: String
        $contactsCursor: String
        $subjectGroupsCursor: String
        $organizationsCursor: String
        $locationsCursor: String
        $subjectsCursor: String
        $sessionCursor: String
        $searchTerm: String
        $includeJobs: Boolean!
        $includeLocations: Boolean!
        $includeContacts: Boolean!
        $includeEmployees: Boolean!
        $includeSubjectGroups: Boolean!
        $includeSubjects: Boolean!
        $includeOrganizations: Boolean!
        $includeSessions: Boolean!
      ) {
        jobs(first: 10, after: $jobsCursor, name_Icontains: $searchTerm)
          @include(if: $includeJobs) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
        gaiaUsers(
          first: 10
          after: $contactsCursor
          organizationContactsSearch: $searchTerm
        ) @include(if: $includeContacts) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              fullName
            }
          }
        }
        locations(
          first: 10
          after: $locationsCursor
          fullAddress_Icontains: $searchTerm
        ) @include(if: $includeLocations) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              fullAddress
              addressLineOne
              shippingAddress
              billingAddress
              addressLineTwo
              city
              state
              zipCode
              archived
              latitude
              mapDefault
              longitude
              studio
              contentType {
                model
                id
              }
              subject {
                id
                gaiaUser {
                  firstName
                  lastName
                }
                organization {
                  name
                }
              }
              organization {
                id
                name
              }
            }
          }
        }
        employees(first: 10, after: $employeesCursor, search: $searchTerm)
          @include(if: $includeEmployees) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                id
                fullName
              }
            }
          }
        }
        subjectGroups(
          first: 10
          after: $subjectGroupsCursor
          subjectGroupOrganizationName: $searchTerm
        ) @include(if: $includeSubjectGroups) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
        organizations(
          first: 10
          after: $organizationsCursor
          name_Icontains: $searchTerm
        ) @include(if: $includeOrganizations) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
        subjects(first: 10, after: $subjectsCursor, search: $searchTerm)
          @include(if: $includeSubjects) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              subjectGroups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              gaiaUser {
                id
                fullName
              }
            }
          }
        }
        sessions(
          first: 10
          after: $sessionCursor
          search_Icontains: $searchTerm
        ) @include(if: $includeSessions) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              job {
                name
              }
              subject {
                gaiaUser {
                  id
                  fullName
                }
              }
              organization {
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )
  useEffect(() => {
    if (globalSearchQueryData) {
      handleQueryResults(globalSearchQueryData)
    }
  }, [globalSearchQueryData])

  useEffect(() => {
    setSearchResults([])
    if (searchTerm.length > 0) {
      globalSearchQuery({
        variables: {
          includeJobs: selectedRecordType === 'Job',
          includeLocations: selectedRecordType === 'Location',
          includeContacts: selectedRecordType === 'Contact',
          includeEmployees: selectedRecordType === 'Employee',
          includeSubjectGroups: selectedRecordType === 'Subject Group',
          includeSubjects: selectedRecordType === 'Subject',
          includeOrganizations: selectedRecordType === 'Organization',
          includeSessions: selectedRecordType === 'Session',
          searchTerm,
          jobsCursor,
          contactsCursor,
          employeesCursor,
          subjectGroupsCursor,
          subjectsCursor,
          locationsCursor,
          organizationsCursor,
          sessionCursor,
        },
      })
    }
  }, [searchTerm])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setEmployeesCursor()
    setJobsCursor()
    setLocationsCursor()
    setContactsCursor()
    setSubjectGroupsCursor()
    setSubjectsCursor()
    setOrganizationsCursor()
    setSessionCursor()
    setSearchTerm(currentSearchTerm)
    setDisplaySearchResults(true)
  }

  const fetchMoreSearchTerms = () => {
    globalSearchQueryFetchMore({
      variables: {
        includeJobs: selectedRecordType === 'Job',
        includeLocations: selectedRecordType === 'Location',
        includeContacts: selectedRecordType === 'Contact',
        includeEmployees: selectedRecordType === 'Employee',
        includeSubjectGroups: selectedRecordType === 'Subject Group',
        includeSubjects: selectedRecordType === 'Subject',
        includeOrganizations: selectedRecordType === 'Organization',
        includeSessions: selectedRecordType === 'Session',
        searchTerm,
        jobsCursor,
        locationsCursor,
        contactsCursor,
        employeesCursor,
        subjectGroupsCursor,
        subjectsCursor,
        organizationsCursor,
        sessionCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        handleQueryResults(fetchMoreResult, true)
        return fetchMoreResult
      },
    })
  }

  const handleQueryResults = (queryResults, fromFetchMore) => {
    if (searchTerm.length === 0) {
      setSearchResults([])
    } else {
      const currentSearchResults = []
      if (
        selectedRecordType === 'Employee' &&
        queryResults.employees.pageInfo.endCursor
      ) {
        setEmployeesCursor(queryResults.employees.pageInfo.endCursor)
        queryResults.employees.edges.forEach((employee) => {
          const employeeNode = employee.node
          currentSearchResults.push({
            nodeType: 'Employee',
            nodeId: employeeNode.id,
            name: employeeNode.gaiaUser.fullName,
          })
        })
      }
      if (
        selectedRecordType === 'Job' &&
        queryResults.jobs.pageInfo.endCursor
      ) {
        setJobsCursor(queryResults.jobs.pageInfo.endCursor)
        queryResults.jobs.edges.forEach((job) => {
          const jobNode = job.node
          currentSearchResults.push({
            nodeType: 'Job',
            nodeId: jobNode.id,
            name: jobNode.name,
          })
        })
      }
      if (
        selectedRecordType === 'Location' &&
        queryResults.locations.pageInfo.endCursor
      ) {
        setLocationsCursor(queryResults.locations.pageInfo.endCursor)
        queryResults.locations.edges.forEach((location) => {
          const locationNode = location.node
          currentSearchResults.push({
            nodeType: 'Location',
            nodeId: locationNode.id,
            node: locationNode,
            name: locationNode.fullAddress,
          })
        })
      }
      if (
        selectedRecordType === 'Contact' &&
        queryResults.gaiaUsers.pageInfo.endCursor
      ) {
        setContactsCursor(queryResults.gaiaUsers.pageInfo.endCursor)
        queryResults.gaiaUsers.edges.forEach((gaiaUser) => {
          const gaiaUserNode = gaiaUser.node
          currentSearchResults.push({
            nodeType: 'Organization Contact',
            nodeId: gaiaUserNode.id,
            name: gaiaUserNode.fullName,
          })
        })
      }
      if (
        selectedRecordType === 'Subject Group' &&
        queryResults.subjectGroups.pageInfo.endCursor
      ) {
        setSubjectGroupsCursor(queryResults.subjectGroups.pageInfo.endCursor)
        queryResults.subjectGroups.edges.forEach((subjectGroup) => {
          const subjectGroupNode = subjectGroup.node
          currentSearchResults.push({
            nodeType: 'Subject Group',
            nodeId: subjectGroupNode.id,
            name: subjectGroupNode.name,
          })
        })
      }
      if (
        selectedRecordType === 'Organization' &&
        queryResults.organizations.pageInfo.endCursor
      ) {
        setOrganizationsCursor(queryResults.organizations.pageInfo.endCursor)
        queryResults.organizations.edges.forEach((organization) => {
          const organizationNode = organization.node
          currentSearchResults.push({
            nodeType: 'Organization',
            nodeId: organizationNode.id,
            name: organizationNode.name,
          })
        })
      }
      if (
        selectedRecordType === 'Subject' &&
        queryResults.subjects.pageInfo.endCursor
      ) {
        setSubjectsCursor(queryResults.subjects.pageInfo.endCursor)
        queryResults.subjects.edges.forEach((subject) => {
          const subjectNode = subject.node
          if (subjectNode.gaiaUser) {
            currentSearchResults.push({
              nodeType: 'Subject',
              nodeId: subjectNode.id,
              name: (
                <>
                  {subjectNode.gaiaUser.fullName}
                  {subjectNode.subjectGroups.edges.length > 0 && (
                    <>
                      <br />
                      {subjectNode.subjectGroups.edges.map((edge) => (
                        <>
                          <span
                            key={edge.node.id}
                            style={{ margin: 0, padding: 0, fontSize: '12px' }}
                            className="mr-2"
                          >
                            <Dot className="mr-2" />
                            {edge.node.name}
                          </span>
                          <br />
                        </>
                      ))}
                    </>
                  )}
                </>
              ),
            })
          }
        })
      }
      if (
        selectedRecordType === 'Session' &&
        queryResults.sessions.pageInfo.endCursor
      ) {
        setSessionCursor(queryResults.sessions.pageInfo.endCursor)
        queryResults.sessions.edges.forEach((session) => {
          const sessionNode = session.node
          if (sessionNode.subject && sessionNode.subject.gaiaUser) {
            currentSearchResults.push({
              nodeType: 'Session',
              nodeId: sessionNode.id,
              name: `${sessionNode.job.name} - ${sessionNode.subject.gaiaUser.fullName}`,
            })
          } else if (sessionNode.organization) {
            currentSearchResults.push({
              nodeType: 'Session',
              nodeId: sessionNode.id,
              name: `${sessionNode.job.name} - ${sessionNode.organization.name}`,
            })
          }
        })
      }
      if (
        (selectedRecordType === 'Employee' &&
          queryResults.employees.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Job' &&
          queryResults.jobs.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Subject Group' &&
          queryResults.subjectGroups.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Contact' &&
          queryResults.gaiaUsers.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Organization' &&
          queryResults.organizations.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Location' &&
          queryResults.locations.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Subject' &&
          queryResults.subjects.pageInfo.hasNextPage) ||
        (selectedRecordType === 'Session' &&
          queryResults.sessions.pageInfo.hasNextPage)
      ) {
        setHasMoreSearchFilters(true)
      } else {
        setHasMoreSearchFilters(false)
      }
      setSearchResults((prevState) => [...prevState, ...currentSearchResults])
    }
  }
  const handleSearchResultClick = (searchResultRow) => {
    setSearchResults([])
    const { nodeType, nodeId, node } = searchResultRow.original
    if (nodeType === 'Employee') {
      history.push(`/employee/${nodeId}`)
    } else if (nodeType === 'Subject') {
      history.push(`/subject/${nodeId}`)
    } else if (nodeType === 'Subject Group') {
      history.push(`/subject-group/${nodeId}`)
    } else if (nodeType === 'Organization') {
      history.push(`/organization/${nodeId}`)
    } else if (nodeType === 'Job') {
      setJobDetailId(nodeId)
      toggleJobDetailModal()
    } else if (nodeType === 'Organization Contact') {
      setContactId(nodeId)
      toggleContactModal()
    } else if (nodeType === 'Location') {
      setLocation(node)
      toggleLocationModal()
    } else if (nodeType === 'Session') {
      setSessionId(nodeId)
      toggleSessionModal()
    }
  }

  const toggleJobDetailModal = () => {
    if (showJobDetailModal) {
      setJobDetailId(null)
    }
    setShowJobDetailModal(!showJobDetailModal)
  }

  const toggleContactModal = () => {
    if (showContactModal) {
      setContactId(null)
    }
    setShowContactModal(!showContactModal)
  }

  const toggleLocationModal = () => {
    if (showLocationModal) {
      setLocation(null)
    }
    setShowLocationModal(!showLocationModal)
  }

  const toggleSessionModal = () => {
    if (showSessionDetailModal) {
      setSessionId(null)
    }
    setShowSessionDetailModal(!showSessionDetailModal)
  }

  const handleControlBlur = () => {
    setSearchTerm('')
    setDisplaySearchResults(false)
  }

  const recordTypeOptions = [
    'Contact',
    'Employee',
    'Job',
    'Location',
    'Organization',
    'Session',
    'Subject',
    'Subject Group',
  ]

  return (
    <>
      <Row>
        <Form.Group
          as={Col}
          md={12}
          className="mb-0 pb-0 d-flex align-items-center"
          style={{ width: '455px' }}
        >
          {settings?.openAiEnabled && (
            <span className="btn-link ms-2" onClick={toggleChatGPTModal}>
              <Robot style={{ fontSize: '20px' }} />
            </span>
          )}
          <span className="btn-link ms-2">
            <Dropdown align="end">
              <Dropdown.Toggle variant="link" id="filter-dropdown">
                <Search style={{ fontSize: '16px' }} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  key={'- - -'}
                  onClick={() => setSelectedRecordType()}
                >
                  - - -
                </Dropdown.Item>
                {recordTypeOptions.map((recordTypeOption) => (
                  <Dropdown.Item
                    key={recordTypeOption}
                    onClick={() => setSelectedRecordType(recordTypeOption)}
                  >
                    {recordTypeOption}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </span>
          <Form.Control
            type="text"
            name="searchTerm"
            placeholder={
              selectedRecordType ? `Search ${selectedRecordType}s` : 'Search'
            }
            value={searchTerm}
            disabled={!selectedRecordType}
            className="form-control-sm flex-grow-1"
            onBlur={handleControlBlur}
            onChange={handleSearchTermChange}
          />
        </Form.Group>
      </Row>
      <Row className="mt-5">
        <Col>
          {loading && (
            <div id="globalSearchInfiniteTable" className="border ms-2">
              <Loading height={'25'} width={'25'} />
            </div>
          )}
          {searchResults.length > 0 && displaySearchResults ? (
            <div id="globalSearchInfiniteTable" className="border">
              <SortableInfiniteTable
                hideGlobalFilter
                tdStyle={{ width: '50%' }}
                tableHeight={200}
                tableData={searchResults}
                tableColumns={[
                  {
                    Header: '',
                    accessor: 'name',
                  },
                ]}
                displayHeader={false}
                fetchMoreTableData={fetchMoreSearchTerms}
                hasMoreTableData={hasMoreSearchFilters}
                onRowClick={handleSearchResultClick}
                rowPointer
              />
            </div>
          ) : null}
        </Col>
      </Row>
      <JobDetailModal
        jobId={jobDetailId}
        toggleModal={toggleJobDetailModal}
        showModal={showJobDetailModal}
      />
      <ContactModal
        contactId={contactId}
        showModal={showContactModal}
        toggleModal={toggleContactModal}
      />
      <LocationModal
        location={location}
        showModal={showLocationModal}
        toggleModal={toggleLocationModal}
      />
      <AdminSessionDetailModal
        sessionId={sessionId}
        showModal={showSessionDetailModal}
        toggleModal={toggleSessionModal}
      />
      <ChatGPTModal
        showModal={showChatGPTModal}
        toggleModal={toggleChatGPTModal}
      />
    </>
  )
}

export default GlobalSearch
