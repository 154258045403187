import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { PlusCircle, Dot } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import TaskCollectionModal from './TaskCollectionModal'

const TaskCollections = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const [editTaskCollection, setEditTaskCollection] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const { loading, error, data } = useQuery(
    gql`
      query TaskCollectionsQuery {
        taskCollections(orderBy: "name", first: 250) {
          edges {
            node {
              id
              name
              contentType {
                model
              }
              collectionType
              taskCollectionTasks(orderBy: "description") {
                edges {
                  node {
                    id
                    description
                    dueDateDelta
                    contentType {
                      model
                    }
                    sharedCanCreateFiles
                    sharedCanCreateFolders
                    sharedCanSeeFiles
                    dueDateDeltaBeforeAfter
                    employees {
                      edges {
                        node {
                          id
                          gaiaUser {
                            fullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )
  const toggleModal = () => {
    if (canMutate) {
      if (showModal) {
        setEditTaskCollection(null)
      }
      setShowModal((prevState) => !prevState)
    }
  }

  useEffect(() => {
    // this useffect trigger on refetch and update the editTask state which we are doing on row click
    let updatedData = { ...data }
    let updatedEditTaskCollection = updatedData?.taskCollections?.edges.filter(
      (node) => {
        return node.node.id === editTaskCollection?.id
      }
    )
    if (updatedEditTaskCollection && updatedEditTaskCollection.length > 0) {
      setEditTaskCollection(updatedEditTaskCollection[0].node)
    }
  }, [data])

  if (loading)
    return (
      <Row className="mt-4">
        <Col md={3}>
          <Loading message="Loading Task Templates..." />
        </Col>
      </Row>
    )
  if (error) return <p>Error loading task types</p>
  return (
    <>
      <div className="taskCollections">
        {canMutate && (
          <Row>
            <Col className="mt-2">
              <div>
                <Button variant="link" onClick={toggleModal}>
                  <PlusCircle className="mr-2" /> New Task Collection
                </Button>
              </div>
            </Col>
          </Row>
        )}
        <Row className="mt-2">
          <Col md={4}>
            {data.taskCollections.edges && (
              <SortableInfiniteTable
                tableData={data.taskCollections.edges}
                loadingMessage="Loading Task Collection..."
                searchPlaceholder={'Search Task Collections'}
                tableColumns={[
                  {
                    Header: 'Name',
                    accessor: 'node.name',
                    serverSort: true,
                    orderBy: 'name',
                  },
                  {
                    Header: 'Type',
                    serverSort: true,
                    orderBy: 'collectionType',
                    accessor: (row) => {
                      if (row.node.collectionType === 'JOB') {
                        return 'Job'
                      } else if (row.node.collectionType === 'ORGANIZATION') {
                        return 'Organization'
                      } else if (row.node.collectionType === 'MISC') {
                        return 'Miscellaneous'
                      }
                    },
                  },
                  {
                    Header: 'Tasks',
                    accessor: (row) => {
                      return (
                        <div
                          style={{
                            maxHeight: '100px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            fontSize: '12px',
                          }}
                          className="mt-2"
                        >
                          {row.node.taskCollectionTasks.edges.map((edge, i) => (
                            <>
                              {i > 0 && <br />}
                              <Dot />
                              {edge.node.description}
                            </>
                          ))}
                        </div>
                      )
                    },
                  },
                ]}
                hasMoreTableData={false}
                tableHeight={800}
                onRowClick={(row) => {
                  setEditTaskCollection(row.original.node)
                  toggleModal()
                }}
                rowPointer={canMutate}
                searchWidth={12}
              />
            )}
          </Col>
        </Row>
        <TaskCollectionModal
          taskCollection={editTaskCollection}
          showModal={showModal}
          toggleModal={toggleModal}
        />
      </div>
    </>
  )
}

export default TaskCollections
