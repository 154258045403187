import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'
import { gql, useMutation, useQuery } from '@apollo/client'
import { PlusCircle, Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'

const GET_SERVICE_ITEM_GROUPS = gql`
  query GetTaskCollections($discludeCollectionType: String) {
    taskCollections(
      orderBy: "name"
      discludeCollectionType: $discludeCollectionType
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const UPDATE_ORGANIZATION_COLLECTIONS = gql`
  mutation UpdateOrganizationCollections(
    $updateOrganizationInput: UpdateOrganizationInput!
  ) {
    updateOrganization(input: $updateOrganizationInput) {
      organization {
        id
      }
    }
  }
`

const UPDATE_JOB_COLLECTIONS = gql`
  mutation UpdateJobCollections($updateJobInput: UpdateJobInput!) {
    updateJob(input: $updateJobInput) {
      job {
        id
      }
    }
  }
`

const AddTaskCollectionsModal = ({
  show,
  handleClose,
  organizationId,
  jobId,
}) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const { data, loading, error } = useQuery(GET_SERVICE_ITEM_GROUPS, {
    fetchPolicy: 'network-only',
    variables: {
      discludeCollectionType: jobId
        ? 'ORGANIZATION'
        : organizationId
          ? 'JOB'
          : null,
    },
  })

  const useCustomMutation = (mutation, refetchQueries) => {
    return useMutation(mutation, {
      onCompleted: () => {
        toast.success('Tasks Added')
        handleClose()
        setSubmitting(false)
      },
      onError: (err) => {
        console.error(err)
        toast.error('An Error Occurred')
        setSubmitting(false)
      },
      refetchQueries: refetchQueries,
    })
  }

  const [updateOrganization] = useCustomMutation(
    UPDATE_ORGANIZATION_COLLECTIONS,
    ['OrganizationDetail', 'Tasks']
  )

  const [updateJob] = useCustomMutation(UPDATE_JOB_COLLECTIONS, [
    'JobDetailQuery',
    'Tasks',
  ])

  if (loading) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Loading />
        </Modal.Body>
      </Modal>
    )
  }

  if (error) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Error loading collections</Modal.Body>
      </Modal>
    )
  }

  const taskCollections = data.taskCollections.edges.map((edge) => edge.node)

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Tasks From Collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            selectedCollections: [],
          }}
          validationSchema={Yup.object({
            selectedCollections: Yup.array().min(
              1,
              'Please select at least one collection'
            ),
          })}
          onSubmit={(values) => {
            setSubmitting(true)
            if (organizationId) {
              updateOrganization({
                variables: {
                  updateOrganizationInput: {
                    organizationInput: {
                      id: organizationId,
                      taskCollectionIds: values.selectedCollections,
                    },
                  },
                },
              })
            }
            if (jobId) {
              updateJob({
                variables: {
                  updateJobInput: {
                    jobInput: {
                      id: jobId,
                      taskCollectionIds: values.selectedCollections,
                    },
                  },
                },
              })
            }
          }}
        >
          {({ values, handleSubmit, errors }) => (
            <Form onSubmit={handleSubmit}>
              <FieldArray
                name="selectedCollections"
                render={(arrayHelpers) => (
                  <>
                    <Form.Group>
                      <Form.Label>Select Collections</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        onChange={(e) => {
                          const selectedId = e.target.value
                          if (
                            selectedId &&
                            !values.selectedCollections.includes(selectedId)
                          ) {
                            arrayHelpers.push(selectedId)
                          }
                        }}
                        value=""
                      >
                        <option value="">---</option>
                        {taskCollections.map((taskCollection) => (
                          <option
                            key={taskCollection.id}
                            value={taskCollection.id}
                          >
                            {taskCollection.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <div>
                      {values.selectedCollections.map((id, index) => {
                        const collection = taskCollections.find(
                          (taskCollection) => taskCollection.id === id
                        )
                        return (
                          <div
                            key={id}
                            className="d-flex align-items-center  p-2 mb-2"
                            style={{
                              border: '1px solid #ddd',
                              borderRadius: '4px',
                            }}
                          >
                            <Button
                              variant="link"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Trash />
                            </Button>

                            <span>{collection?.name}</span>
                          </div>
                        )
                      })}
                    </div>

                    {errors.selectedCollections && (
                      <div className="text-danger">
                        {errors.selectedCollections}
                      </div>
                    )}

                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        type="submit"
                        variant="outline-primary"
                        block
                        disabled={isSubmitting}
                      >
                        <PlusCircle className="mr-2" /> Save
                      </Button>
                    </div>
                    {isSubmitting && <Loading />}
                  </>
                )}
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddTaskCollectionsModal
