import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { Trash, PlusCircle } from 'react-bootstrap-icons'
import DeleteFormModal from './DeleteFormModal'
import { loggedInUserVar } from '../../libs/apollo'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Forms = () => {
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [searchText, setSearchText] = useState('Search Forms...')
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedFormIds, setCheckedFormIds] = useState([])

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Forms(
        $cursor: String # $searchTerm: String
      ) # $orderBy: String
      {
        forms(first: 20, after: $cursor) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              subject {
                id
                gaiaUser {
                  fullName
                }
              }
              organization {
                id
                name
              }
              job {
                id
                name
              }
              subjectGroup {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const constructQueryVariables = () => {
    const variables = {
      cursor: queryData?.forms?.pageInfo?.endCursor,
    }
    // if (searchTerm) {
    //   variables.searchTerm = searchTerm
    // }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = constructQueryVariables()
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.forms) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      let text = 'Search Forms'
      if (queryData.forms.nodeCount > 0) {
        text = `Search ${queryData.forms.nodeCount} Forms`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    queryFetchMore({
      variables,
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = constructQueryVariables()
    variables.cursor = null
    // variables.searchTerm = currentSearchTerm
    setLoadingSearch(true)
    query({ variables })
  }

  const onTdClick = (cell) => {
    history.push(`/form/${cell.row.original.node.id}`)
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
      serverSort: true,
    },
  ]

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <Row className="mt-3 mb-3">
        <Col>
          <div className="d-flex align-items-center">
            <h1 className="mb-0 d-inline">Forms</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          {canMutate && (
            <Button
              variant="link"
              onClick={() => {
                history.push('/form')
              }}
            >
              <PlusCircle className="mr-2" />
              New Form
            </Button>
          )}
          {checkedFormIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedFormIds.length === 1 ? (
                    <>Delete Form</>
                  ) : (
                    <>Delete Forms</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={queryData?.forms?.edges ? queryData?.forms?.edges : []}
            loadingMessage="Loading Forms..."
            tableColumns={tableColumns}
            loading={loadingSearch || !queryData}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.forms?.pageInfo?.hasNextPage}
            onTdClicks={{
              name: (cell) => onTdClick(cell),
            }}
            tableHeight={700}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
      <DeleteFormModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedFormIds={setCheckedFormIds}
        ids={checkedFormIds.length > 1 ? checkedFormIds : null}
        id={checkedFormIds.length === 1 ? checkedFormIds[0] : null}
      />
    </>
  )
}
export default Forms
