import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import NewSubjectModal from './NewSubjectModal'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { Dot, CloudArrowDown, PlusCircle } from 'react-bootstrap-icons'
import { Person, Trash } from 'react-bootstrap-icons'
import DeleteSubjectModal from './DeleteSubjectModal'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import { useDownloadFile } from '../../libs/downloadFile'

const Subjects = (props) => {
  const { organization, contactId } = props
  const { contact } = useParams()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canModify =
    [
      'Administrator',
      'Scheduling Manager',
      'Scheduling Analyst',
      'Organization Contact',
    ].includes(loggedInUser?.permissions?.group) ||
    loggedInUser.permissions.isOrgContact
  const defaultOrderBy = 'gaia_user__full_name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [hasMoreSubjects, setHasMoreSubjects] = useState(true)
  const [searchText, setSearchText] = useState()
  const [cursor, setCursor] = useState()
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [checkedSubjectIds, setCheckedSubjectIds] = useState([])
  const [showDeleteSubjectsModal, setShowDeleteEmployeesModal] = useState(false)
  const [subjects, setSubjects] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showSubjectModal, setShowSubjectModal] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const { downloadAndDeleteFile } = useDownloadFile()
  const history = useHistory()

  const [downloadSubjects] = useMutation(
    gql`
      mutation DownloadSubjects($input: DownloadSubjectsInput!) {
        downloadSubjects(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadSubjects.file.fileName,
          data.downloadSubjects.file.displayName,
          data.downloadSubjects.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      id: 'name',
      serverSort: true,
      orderBy: 'gaia_user__full_name',
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (row) => {
        let email
        if (!row.node.gaiaUser?.dummyUsername) {
          email = row.node.gaiaUser?.email
          if (row.node.gaiaUser?.secondaryEmail) {
            email = `${email}, ${row.node.gaiaUser?.secondaryEmail}`
          }
        }
        return <>{email}</>
      },
      serverSort: true,
      orderBy: 'gaia_user__email',
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      serverSort: true,
      orderBy: 'gaia_user__phone_number',
    },
    {
      Header: 'ID Number',
      accessor: 'studentId',
      id: 'studentId',
      serverSort: true,
      orderBy: 'student_id',
    },
    {
      Header: 'Subject Groups',
      id: 'subjectGroup',
      accessor: (row) => {
        return (
          <>
            {row.node.subjectGroupSubjects.edges.map((subjectGroupSubject) => (
              <>
                <span
                  key={subjectGroupSubject.node.subjectGroup.id}
                  style={{ margin: 0, padding: 0 }}
                  className="mr-2"
                >
                  <span
                    onClick={() => {
                      if (contact) {
                        history.push(`/subject/${row.node.id}/contact`)
                      } else {
                        history.push(`/subject/${row.node.id}`)
                      }
                    }}
                  >
                    <Dot className="mr-2" />
                    {subjectGroupSubject.node.subjectGroup.name}
                  </span>
                  {subjectGroupSubject.node.imagequixSubject?.imagequixId && (
                    <span
                      className="ml-2 btn-link"
                      style={{ fontSize: '14px' }}
                      onClick={() => {
                        window.open(
                          `https://vando.imagequix.com/s${subjectGroupSubject.node.imagequixSubject?.imagequixId}`,
                          '_blank'
                        )
                      }}
                    >
                      Gallery
                    </span>
                  )}
                </span>
                <br />
              </>
            ))}
          </>
        )
      },
    },
  ]
  if (!organization) {
    tableColumns.splice(5, 0, {
      Header: 'Organization',
      accessor: 'organization',
      id: 'organization',
      serverSort: true,
      orderBy: 'organization__name',
    })
  }
  if (canModify) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  subjects.forEach((subject) => {
                    if (!checkedSubjectIds.includes(subject.node.id)) {
                      appendIds.push(subject.node.id)
                    }
                  })
                  setCheckedSubjectIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedSubjectIds([])
                }
              }}
            />
            {checkedSubjectIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedSubjectIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedSubjectIds.includes(row.node.id)}
                onChange={(e) => handleSubjectCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  const handleSubjectCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedSubjectIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedSubjectIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const [query, { error, data: queryData, fetchMore }] = useLazyQuery(
    gql`
      query Subjects(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $subjectContact: String
        $organization: ID
      ) {
        subjects(
          first: 50
          after: $cursor
          search: $searchTerm
          subjectContact: $subjectContact
          orderBy: $orderBy
          organization: $organization
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                jobTitle
                fullName
                email
                dummyUsername
                phoneNumber
                lastLogin
                loginLocation
                loginBrowser
              }
              organization {
                id
                name
              }
              studentId
              subjectGroupSubjects {
                edges {
                  node {
                    imagequixSubject {
                      imagequixId
                    }
                    subjectGroup {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 10000,
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {
        orderBy: defaultOrderBy,
      }
      if (organization) {
        variables.organization = organization.id
      }
      if (contactId) {
        variables.subjectContact = contactId
      }
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.subjects) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      let text = 'Search Subjects'
      setSearchText(text)
      if (queryData.subjects.pageInfo.endCursor) {
        setCursor(queryData.subjects.pageInfo.endCursor)
      }
      setHasMoreSubjects(queryData.subjects.pageInfo.hasNextPage)
      const currentSubjects = queryData.subjects.edges.map((subject) => {
        const subjectNode = subject.node
        let email = subjectNode?.gaiaUser?.dummyUsername
          ? ''
          : subjectNode?.gaiaUser?.email
        return {
          email,
          node: subjectNode,
          jobTitle: subjectNode.gaiaUser?.jobTitle,
          name: subjectNode.gaiaUser?.fullName,
          phoneNumber: subjectNode.gaiaUser?.phoneNumber,
          studentId: subjectNode.studentId,
          id: subjectNode.id,
          organization: (
            <>
              {subjectNode.organization && (
                <p
                  key={subjectNode.organization.id}
                  style={{ margin: 0, padding: 0 }}
                >
                  {subjectNode.organization.name}
                </p>
              )}
            </>
          ),
        }
      })
      setSubjects(currentSubjects)
    }
  }, [queryData])

  const fetchMoreTableData = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (organization) {
      variables.organization = organization.id
    }
    if (contactId) {
      variables.subjectContact = contactId
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    fetchMore({
      variables,
    })
  }

  const subjectModalShowChange = () => {
    setShowSubjectModal((showSubjectModal) => !showSubjectModal)
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setCursor(null)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (organization) {
      variables.organization = organization.id
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (contactId) {
      variables.subjectContact = contactId
    }
    setLoadingSearch(true)
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return
    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (organization) {
      variables.organization = organization.id
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    if (contact) {
      history.push(`/subject/${cell.row.original.id}/contact`)
    } else {
      history.push(`/subject/${cell.row.original.id}`)
    }
  }

  const onTdClicks = {
    name: onTdClick,
    email: onTdClick,
    phoneNumber: onTdClick,
    studentId: onTdClick,
    organization: onTdClick,
    jobTitle: onTdClick,
  }

  if (!initialQueryRun && !queryData)
    return (
      <div className="mt-3">
        <Loading />
      </div>
    )
  if (error) return <>Error loading subject</>
  return (
    <>
      {!organization && !contactId && (
        <Row className="mt-3">
          <Col>
            <div className="d-flex align-items-center">
              <h1 className="mb-0 d-inline">Subjects</h1>
            </div>
          </Col>
        </Row>
      )}
      {canModify && !contactId && (
        <Row className="mt-2 mb-2">
          <Col>
            <>
              <Button variant="link" onClick={() => subjectModalShowChange()}>
                <PlusCircle className="mr-2" />
                New Subject
              </Button>
              <Button
                variant="link"
                disabled={downloadingPdf}
                onClick={() => {
                  setDownloadingPdf(true)
                  downloadSubjects({
                    variables: {
                      input: {
                        subjectIds: checkedSubjectIds,
                        fileType: 'pdf',
                      },
                    },
                  })
                }}
              >
                {downloadingPdf && <Loading />}
                {!downloadingPdf && !downloadingExcel && (
                  <>
                    <CloudArrowDown className="mr-2" />
                    Download PDF
                  </>
                )}
              </Button>
              <Button
                variant="link"
                disabled={downloadingExcel}
                onClick={() => {
                  setDownloadingExcel(true)
                  downloadSubjects({
                    variables: {
                      input: {
                        subjectIds: checkedSubjectIds,
                        fileType: 'xlsx',
                      },
                    },
                  })
                }}
              >
                {downloadingExcel && <Loading />}
                {!downloadingPdf && !downloadingExcel && (
                  <>
                    <CloudArrowDown className="mr-2" />
                    Download Excel
                  </>
                )}
              </Button>
            </>
            {checkedSubjectIds.length > 0 && (
              <Button
                variant="link"
                onClick={() => setShowDeleteEmployeesModal(true)}
              >
                <Trash className="mr-2" />
                {checkedSubjectIds.length === 1
                  ? 'Delete Subject'
                  : 'Delete Subjects'}
              </Button>
            )}
          </Col>
        </Row>
      )}

      {organization && <div className="mb-2" />}
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              size="sm"
              type="text"
              name="searchTerm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <SortableInfiniteTable
            tableData={subjects}
            loading={loadingSearch}
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMoreTableData}
            hasMoreTableData={hasMoreSubjects}
            onTdClicks={onTdClicks}
            loadingMessage="Loading Subjects..."
            tableHeight={700}
            rowPointer
            hideGlobalFilter
            handleSortByChange={handleSortByChange}
          />
        </Col>
      </Row>
      {showSubjectModal && (
        <NewSubjectModal
          showSubjectModal={showSubjectModal}
          subjectModalShowChange={subjectModalShowChange}
          setShowSubjectModal={setShowSubjectModal}
          organization={organization}
          displayBilling
        />
      )}
      {showDeleteSubjectsModal && (
        <DeleteSubjectModal
          showModal={showDeleteSubjectsModal}
          toggleModal={setShowDeleteEmployeesModal}
          subjectIds={checkedSubjectIds}
          onComplete={() => setCheckedSubjectIds([])}
        />
      )}
    </>
  )
}
export default Subjects
