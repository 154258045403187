import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal, Table, Tab } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import DatePicker from 'react-datepicker'
import InfiniteScroll from 'react-infinite-scroll-component'
import { objFull } from '../../libs/utils'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import {
  PlusCircle,
  Trash,
  Bell,
  CardChecklist,
  ClockHistory,
  FileEarmarkText,
} from 'react-bootstrap-icons'
import Loading from '../common/Loading'
import FilesTable from '../files/FilesTable'
import OrganizationSearchInput from '../common/node_search_input/OrganizationSearchInput'
import JobSearchInput from '../common/node_search_input/JobSearchInput'
import TagSearchInput from '../common/node_search_input/TagSearchInput'
import { useFolderConfiguration } from '../../libs/folderConfiguration'
import AuditLog from '../audit_log/AuditLog'
import NotificationSender from '../notifications/NotificationSender'
import Tabs from '../common/Tabs'

const TaskModal = (props) => {
  const {
    taskId,
    showModal,
    toggleModal,
    organizationId,
    organizationName,
    jobId,
    jobName,
    updateTask,
    employeeDetail,
  } = props
  const folderConfiguration = useFolderConfiguration()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const isAdmin = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions.group
  )
  const isEmployee = loggedInUser?.permissions.isEmployee
  const [employeeValue, setEmployeeValue] = useState('')
  const [activeTab, setActiveTab] = useState('Files')
  const [taskNotificationTriggerId, setTaskNotificationTriggerId] = useState()
  const [displayCompletedEmployeeResults, setDisplayCompletedEmployeeResults] =
    useState(false)

  const [_updateTask, setUpdateTask] = useState(updateTask)
  const [displayEmployeeResults, setDisplayEmployeeResults] = useState(false)
  const [contactsOptionValue, setContactsOptionValue] = useState('dash')
  const [contactsArr, setContactsArr] = useState([])
  const [newTaskGroup, setNewTaskGroup] = useState(false)
  const statusChoices = ['To Do', 'In Progress', 'Complete']

  const [deleteMutation] = useMutation(
    gql`
      mutation DeleteTask($input: DeleteTaskInput!) {
        deleteTask(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Task Deleted')
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: ['Organization', 'OrganizationDetail', 'Tasks'],
      variables: {
        input: {
          taskIds: [_updateTask?.id],
        },
      },
    }
  )
  const [taskCollections, { data: taskCollectionsData }] = useLazyQuery(
    gql`
      query TaskCollectionsQuery {
        taskCollections(orderBy: "name") {
          edges {
            node {
              id
              name
              default
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [create] = useMutation(
    gql`
      mutation CreateTask($input: CreateTaskInput!) {
        createTask(input: $input) {
          task {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Task Saved')
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: ['Organization', 'Tasks', 'OrganizationDetail'],
    }
  )

  const [update] = useMutation(
    gql`
      mutation UpdateTask($input: UpdateTaskInput!) {
        updateTask(input: $input) {
          task {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Task Saved')
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: ['Organization', 'Tasks', 'OrganizationDetail'],
    }
  )

  const [searchEmployee, { data: employeesData }] = useLazyQuery(
    gql`
      query EmployeesQuery($cursor: String, $searchTerm: String) {
        employees(
          first: 20
          after: $cursor
          gaiaUser_FullName_Icontains: $searchTerm
          orderBy: "gaia_user__full_name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                email
                fullName
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  const [queryTask] = useLazyQuery(
    gql`
      query Task($id: ID!) {
        task(id: $id) {
          id
          description
          notes
          dueDate
          archive
          sharedCanSeeFiles
          contentType {
            model
          }
          folder {
            id
          }
          sharedFolder {
            id
          }
          sharedCanCreateFiles
          sharedCanCreateFolders
          completedByEmployee {
            id
            gaiaUser {
              abbreviatedName
              fullName
            }
          }
          eventDate
          finishedDate
          status
          organization {
            id
            name
          }
          taskCollection {
            name
            id
          }
          job {
            id
            name
          }
          tags {
            id
            name
          }
          employees {
            edges {
              node {
                id
                gaiaUser {
                  id
                  abbreviatedName
                  fullName
                  email
                }
              }
            }
          }
          contacts {
            edges {
              node {
                id
                fullName
                email
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setUpdateTask(data.task)
      },
    }
  )
  const [notificationTriggersQuery] = useLazyQuery(
    gql`
      query NotificationTriggers($name: String) {
        notificationTriggers(name_Iexact: $name) {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        name: 'Task',
      },
      onCompleted: (data) => {
        setTaskNotificationTriggerId(data.notificationTriggers.edges[0].node.id)
      },
    }
  )

  useEffect(() => {
    if (taskId) {
      queryTask({ variables: { id: taskId } })
    }
  }, [taskId])

  const [searchCompletedEmployee, { data: completedEmployeesData }] =
    useLazyQuery(
      gql`
        query CompletedEmployeesQuery($cursor: String, $searchTerm: String) {
          employees(
            first: 20
            after: $cursor
            gaiaUser_FullName_Icontains: $searchTerm
            orderBy: "gaia_user__full_name"
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                gaiaUser {
                  email
                  fullName
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      }
    )

  const [contactsQuery, { data: contactsData }] = useLazyQuery(
    gql`
      query Organization($organizationId: ID!) {
        organization(id: $organizationId) {
          taskSharedCanCreateFiles
          taskSharedCanCreateFolders
          taskSharedCanSeeFiles
          contacts(archived: false) {
            edges {
              node {
                id
                fullName
                email
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        organizationId: organizationId ? organizationId : '',
      },
    }
  )

  const [orgContactsQuery] = useLazyQuery(
    gql`
      query OrganizationContacts($organizationId: [ID]) {
        gaiaUsers(organizationContacts: $organizationId) {
          edges {
            node {
              id
              fullName
              email
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setContactsArr(data.gaiaUsers.edges)
      },
    }
  )

  const handleCompletedEmployeeClick = (employeeNode) => {
    formik.setFieldValue('completedEmployeeId', employeeNode.node.id)
    formik.setFieldValue(
      'completedEmployeeName',
      employeeNode.node.gaiaUser.fullName
    )
  }

  const handleCompletedEmployeeChange = (e) => {
    if (e.target.value != '') {
      searchCompletedEmployee({
        variables: {
          searchTerm: e.target.value,
        },
        fetchPolicy: 'network-only',
      })
      setDisplayCompletedEmployeeResults(true)
    } else {
      setDisplayCompletedEmployeeResults(false)
    }
  }

  const handleCompletedEmployeeBlur = () => {
    setDisplayCompletedEmployeeResults(false)
    formik.setFieldTouched('completedEmployeeId', true)
  }

  const handleCompletedEmployeeXClick = () => {
    formik.setFieldValue(`completedEmployeeName`, '')
    formik.setFieldValue(`completedEmployeeId`, '')
  }

  useEffect(() => {
    if (activeTab === 'Notifications') {
      notificationTriggersQuery()
    }
  }, [activeTab])

  const handleEmployeeBlur = () => {
    setDisplayEmployeeResults(false)
    formik.setFieldTouched('employees', true)
    setEmployeeValue('')
  }

  const handleContactsClick = (e) => {
    const index = contactsArr.findIndex((x) => x.node.id === e.target.value)
    if (index > -1) {
      const currentContacts = [...formik.values.contacts]
      currentContacts.push(contactsArr[index])
      formik.setFieldValue('contacts', currentContacts)
      setContactsOptionValue('dash')
    }
  }

  const handleEmployeeClick = (employeeNode) => {
    const newEmployees = [employeeNode, ...formik.values.employees]
    formik.setFieldValue('employees', newEmployees)
  }

  const handleEmployeeChange = (e) => {
    setEmployeeValue(e.target.value)
    if (e.target.value != '') {
      searchEmployee({
        variables: {
          searchTerm: e.target.value,
        },
        fetchPolicy: 'network-only',
      })
      setDisplayEmployeeResults(true)
    } else {
      setDisplayEmployeeResults(false)
    }
  }

  const handleRemoveContacts = (contact) => {
    const currentContacts = [...formik.values.contacts]
    currentContacts.splice(currentContacts.indexOf(contact), 1)
    formik.setFieldValue('contacts', currentContacts)
  }

  const handleRemoveEmployee = (employee) => {
    formik.setFieldValue(
      'employees',
      formik.values.employees.filter((emp) => emp.node.id !== employee.node.id)
    )
  }

  const formik = useFormik({
    initialValues: {
      taskCollection: '',
      newTaskCollection: '',
      organizationId: organizationId || '',
      organizationDescription: '',
      jobId: jobId || '',
      jobDescription: '',
      description: '',
      status: 'To Do',
      eventDate: null,
      dueDate: null,
      finishedDate: null,
      contacts: [],
      employees: [],
      notes: '',
      sharedCanSeeFiles: false,
      sharedCanCreateFiles: false,
      sharedCanCreateFolders: false,
      archive: false,
      tags: [],
    },
    validationSchema: Yup.object().shape({
      taskCollection: Yup.string().nullable(),
      newTaskCollection: Yup.string()
        .nullable()
        .test(
          'valid',
          'cannot choose an existing task collection and new task collection',
          (value, context) => {
            let valid = true
            if (
              value &&
              context.parent.taskCollection &&
              context.parent.taskCollection !== 'none'
            ) {
              valid = false
            }
            return valid
          }
        )
        .test('valid', 'task collection already exists', (value) => {
          let valid = true
          if (taskCollectionsData?.taskCollections?.edges) {
            taskCollectionsData.taskCollections.edges.forEach(
              (taskCollection) => {
                if (taskCollection.node.name === value) {
                  valid = false
                }
              }
            )
          }
          return valid
        }),
      description: Yup.string().nullable(),
      status: Yup.string()
        .required('required')
        .test('isStatus', 'invalid status', (value) => {
          let valid = false
          if (value && ['In Progress', 'To Do', 'Complete'].includes(value)) {
            valid = true
          }
          return valid
        }),
      eventDate: Yup.string().nullable(),
      dueDate: Yup.string().nullable(),
      finishedDate: Yup.string().nullable(),
      notes: Yup.string().nullable(),
      sharedCanSeeFiles: Yup.boolean().required('Required'),
      sharedCanCreateFiles: Yup.boolean().required('Required'),
      sharedCanCreateFolders: Yup.boolean().required('Required'),
      archive: Yup.boolean().nullable(),
      organizationId: Yup.string().nullable(),
      jobId: Yup.string().nullable(),
      tags: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      const addContactsIds = values.contacts.map((contactsData) => {
        return contactsData.node.id
      })
      const addEmployeesIds = values.employees.map((employeesData) => {
        return employeesData.node.id
      })
      let taskCollectionId = null
      if (values.taskCollection && values.taskCollection !== 'none') {
        taskCollectionId = values.taskCollection
      }
      let newTaskCollection
      if (values.newTaskCollection) {
        newTaskCollection = values.newTaskCollection
      }
      if (objFull(_updateTask)) {
        update({
          variables: {
            input: {
              taskInput: {
                id: _updateTask.id,
                description: values.description,
                notes: values.notes,
                status: values.status,
                dueDate: values.dueDate,
                taskCollectionId,
                completedByEmployeeId: values.completedEmployeeId,
                taskCollectionName: newTaskCollection,
                eventDate: values.eventDate,
                finishedDate: values.finishedDate,
                contactIds: addContactsIds,
                employeeIds: addEmployeesIds,
                sharedCanSeeFiles: values.sharedCanSeeFiles,
                sharedCanCreateFiles: values.sharedCanCreateFiles,
                sharedCanCreateFolders: values.sharedCanCreateFolders,
                archive: values.archive,
                organizationId: values.organizationId,
                jobId: values.jobId,
                tags: values.tags.map((tag) => {
                  return tag.description
                }),
              },
            },
          },
        })
      } else {
        create({
          variables: {
            input: {
              taskInput: {
                description: values.description,
                notes: values.notes,
                completedByEmployeeId: values.completedEmployeeId,
                status: values.status,
                dueDate: values.dueDate,
                taskCollectionId,
                taskCollectionName: newTaskCollection,
                eventDate: values.eventDate,
                finishedDate: values.finishedDate,
                contactIds: addContactsIds,
                employeeIds: addEmployeesIds,
                sharedCanSeeFiles: values.sharedCanSeeFiles,
                sharedCanCreateFiles: values.sharedCanCreateFiles,
                sharedCanCreateFolders: values.sharedCanCreateFolders,
                organizationId: values.organizationId,
                jobId: values.jobId,
                tags: values.tags.map((tag) => {
                  return tag.description
                }),
              },
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (organizationId || formik?.values.organizationId) {
      orgContactsQuery({
        variables: {
          organizationId: formik.values.organizationId
            ? formik.values.organizationId
            : organizationId,
        },
      })
    } else {
      setContactsArr([])
      formik.setFieldValue('contacts', [])
    }
  }, [organizationId, formik?.values.organizationId])

  useEffect(() => {
    if (objFull(_updateTask) && taskCollectionsData) {
      let taskCollectionId =
        taskCollectionsData.taskCollections.edges.find(
          (edge) => edge.node.id === _updateTask.taskCollection?.id
        ) || 'none'
      if (taskCollectionId !== 'none') {
        taskCollectionId = taskCollectionId.node.id
      }
      formik.setValues({
        taskCollection: taskCollectionId,
        newTaskCollection: '',
        organizationId: _updateTask.organization?.id || organizationId,
        organizationDescription: _updateTask.organization?.name,
        jobId: _updateTask.job?.id || jobId,
        jobDescription: _updateTask.job?.name,
        tags: _updateTask.tags?.map((tag) => {
          return {
            description: tag.name,
            id: tag.id,
          }
        }),
        description: _updateTask.description || '',
        completedEmployeeId:
          _updateTask.completedByEmployee?.id &&
          _updateTask.completedByEmployee?.id,
        completedEmployeeName:
          _updateTask.completedByEmployee?.gaiaUser?.fullName &&
          _updateTask.completedByEmployee?.gaiaUser?.fullName,
        status: _updateTask.status || 'To Do',
        eventDate: _updateTask.eventDate
          ? new Date(_updateTask?.eventDate)
          : null,
        dueDate: _updateTask.dueDate ? new Date(_updateTask?.dueDate) : null,
        finishedDate: _updateTask.finishedDate
          ? new Date(_updateTask?.finishedDate)
          : null,
        contacts: _updateTask.contacts.edges,
        employees: _updateTask.employees.edges,
        notes: _updateTask.notes || '',
        sharedCanSeeFiles: _updateTask.sharedCanSeeFiles,
        sharedCanCreateFiles: _updateTask.sharedCanCreateFiles,
        sharedCanCreateFolders: _updateTask.sharedCanCreateFolders,
        archive: _updateTask.archive,
      })
    }
  }, [_updateTask, taskCollectionsData])

  useEffect(() => {
    if (objFull(_updateTask)) return

    const newValues = {
      sharedCanSeeFiles:
        contactsData?.organization?.taskSharedCanSeeFiles ??
        folderConfiguration?.taskSharedCanSeeFiles,
      sharedCanCreateFiles:
        contactsData?.organization?.taskSharedCanCreateFiles ??
        folderConfiguration?.taskSharedCanCreateFiles,
      sharedCanCreateFolders:
        contactsData?.organization?.taskSharedCanCreateFolders ??
        folderConfiguration?.taskSharedCanCreateFolders,
    }
    const shouldUpdate = Object.keys(newValues).some(
      (key) => formik.values[key] !== newValues[key]
    )
    if (shouldUpdate) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...newValues,
      }))
    }
  }, [contactsData, folderConfiguration, showModal])

  useEffect(() => {
    if (showModal) {
      taskCollections()
      contactsQuery()
    }
  }, [showModal, organizationId])

  const innerToggle = () => {
    formik.resetForm()
    toggleModal()
  }
  if (!taskCollectionsData || !contactsData) return <></>
  return (
    <>
      <div className="newSessionResitReasonModal">
        <Modal
          size={'xl'}
          show={showModal}
          onHide={innerToggle}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <CardChecklist className="mr-2" />
                {objFull(_updateTask) ? (
                  <>
                    {employeeDetail ? (
                      <>Task</>
                    ) : (
                      <>
                        Update Task{' '}
                        {organizationId && organizationName && (
                          <>For {organizationName} </>
                        )}
                        {jobId && jobName && <>For {jobName} </>}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    New Task{' '}
                    {organizationId && organizationName && (
                      <>For {organizationName} </>
                    )}
                    {jobId && jobName && <>For {jobName} </>}
                  </>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Name
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    name="description"
                    className="form-control-sm"
                    disabled={!isAdmin}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                {!newTaskGroup && isEmployee && (
                  <>
                    <Form.Label column sm="2">
                      Task Collection
                    </Form.Label>
                    <Col sm="6">
                      <select
                        className="form-control-sm form-select"
                        name="taskCollection"
                        disabled={!isAdmin}
                        onChange={formik.handleChange}
                        value={formik.values.taskCollection}
                      >
                        <option value="none">- - -</option>
                        {taskCollectionsData.taskCollections.edges.map(
                          (taskCollection) => {
                            let descr = taskCollection.node.name
                            return (
                              <option
                                key={taskCollection.node.id}
                                value={taskCollection.node.id}
                              >
                                {descr}
                              </option>
                            )
                          }
                        )}
                      </select>
                      {formik.errors.taskCollection && (
                        <span style={{ color: 'red' }}>
                          {formik.errors.taskCollection}
                        </span>
                      )}
                    </Col>
                    {isAdmin && (
                      <>
                        <Form.Label column sm="2">
                          New Task Collection
                        </Form.Label>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={newTaskGroup}
                            onChange={() => setNewTaskGroup(!newTaskGroup)}
                          />
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Form.Group>
              {isAdmin && newTaskGroup && (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Task Collection Name
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      name="newTaskCollection"
                      value={formik.values.newTaskCollection}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.newTaskCollection}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.newTaskCollection}
                    </Form.Control.Feedback>
                  </Col>
                  <Form.Label column sm="2">
                    New Task Collection
                  </Form.Label>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={newTaskGroup}
                      onChange={() => setNewTaskGroup(!newTaskGroup)}
                    />
                  </Col>
                </Form.Group>
              )}
              {isEmployee && (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Notes
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="textarea"
                      name="notes"
                      style={{ height: 100 }}
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.notes}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.notes}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Status
                </Form.Label>
                <Col sm="6">
                  <select
                    className="form-control-sm form-select"
                    name="status"
                    disabled={!isEmployee}
                    onChange={formik.handleChange}
                    value={formik.values.status}
                  >
                    {statusChoices.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.status}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}></Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Event On
                </Form.Label>
                <Col>
                  <DatePicker
                    name="eventDate"
                    className="form-control form-control-sm"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    disabled={!isAdmin}
                    selected={formik.values.eventDate}
                    onChange={(date) => formik.setFieldValue('eventDate', date)}
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
                <Form.Label column sm="2">
                  Due On
                </Form.Label>
                <Col>
                  <DatePicker
                    name="dueDate"
                    className="form-control form-control-sm"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    disabled={!isAdmin}
                    selected={formik.values.dueDate}
                    onChange={(date) => formik.setFieldValue('dueDate', date)}
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
                <Form.Label column sm="2">
                  Completed On
                </Form.Label>
                <Col>
                  <DatePicker
                    name="finishedDate"
                    className="form-control form-control-sm"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    disabled={!isAdmin}
                    selected={formik.values.finishedDate}
                    onChange={(date) =>
                      formik.setFieldValue('finishedDate', date)
                    }
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Tags
                  </Form.Label>
                  <Col sm="6">
                    <TagSearchInput
                      formik={formik}
                      multiple
                      create
                      disabled={!isAdmin}
                    />
                  </Col>
                </Form.Group>
                {!organizationId && (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Organization
                    </Form.Label>
                    <Col sm="6">
                      <OrganizationSearchInput
                        searchDescription="Organizations"
                        formik={formik}
                        disabled={!isAdmin}
                        error={formik.errors.organizationId}
                      />
                    </Col>
                  </Form.Group>
                )}
                {!jobId && (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Job
                    </Form.Label>
                    <Col sm="6">
                      <JobSearchInput
                        formik={formik}
                        error={formik.errors.jobId}
                        disabled={!isAdmin}
                      />
                    </Col>
                  </Form.Group>
                )}
              </>
              {(organizationId || formik.values.organizationId) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Contacts
                  </Form.Label>
                  <Col sm="6">
                    {contactsArr.length > 0 ? (
                      <>
                        <Form.Control
                          name="contacts"
                          as="select"
                          className="form-control-sm"
                          disabled={!isAdmin}
                          value={contactsOptionValue}
                          onChange={(e) => handleContactsClick(e)}
                        >
                          <option value="dash">Select Contacts</option>
                          {contactsArr
                            .filter((contact) => {
                              let show = true
                              formik.values.contacts.forEach((selected) => {
                                if (selected?.node.id === contact?.node.id) {
                                  show = false
                                }
                              })
                              return show
                            })
                            .map((data) => (
                              <option key={data.node.id} value={data.node.id}>
                                {data.node.fullName}, {data.node.email}
                              </option>
                            ))}
                        </Form.Control>
                        <div>
                          {formik.values.contacts.map((contact, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'center', // Align items vertically in the center
                                fontSize: 13,
                              }}
                              className="p-1 border border-dark rounded mt-2"
                            >
                              {isAdmin && (
                                <span
                                  className="btn-link"
                                  onClick={() => {
                                    handleRemoveContacts(contact)
                                  }}
                                >
                                  <Trash size={14} className="mr-2" />
                                </span>
                              )}
                              <span style={{ marginLeft: 8 }}>
                                {contact.node.fullName}, {contact.node.email}
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        {isEmployee && (
                          <small className="ml-1">
                            Organization Has No Contacts
                          </small>
                        )}
                      </>
                    )}
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Assigned To
                </Form.Label>
                <Col sm="6">
                  {isAdmin && (
                    <Form.Control
                      placeholder="Search Employee"
                      className="form-control-sm"
                      value={employeeValue}
                      disabled={!isAdmin}
                      onBlur={handleEmployeeBlur}
                      onChange={(e) => handleEmployeeChange(e)}
                    />
                  )}

                  {employeesData && displayEmployeeResults ? (
                    <InfiniteScroll
                      height={100}
                      dataLength={employeesData?.employees?.edges.length}
                      hasMore={employeesData?.employees?.pageInfo.hasNextPage}
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {employeesData?.employees?.edges.map((employee) => {
                            let show = true
                            formik.values.employees.forEach((selected) => {
                              if (selected?.node.id === employee.node.id) {
                                show = false
                              }
                            })
                            if (show) {
                              return (
                                <tr
                                  onMouseDown={() =>
                                    handleEmployeeClick(employee)
                                  }
                                  key={employee.node.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small>
                                      {employee.node.gaiaUser.fullName}
                                    </small>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  ) : null}
                  <div>
                    {formik.values.employees.map((employee, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center', // Align items vertically in the center
                          fontSize: 13,
                        }}
                        className="p-1 border border-dark rounded mt-2"
                      >
                        {isAdmin && (
                          <span
                            className="btn-link"
                            onClick={() => {
                              handleRemoveEmployee(employee)
                            }}
                          >
                            <Trash size={14} className="mr-2" />
                          </span>
                        )}
                        <span style={{ marginLeft: 8 }}>
                          {employee.node.gaiaUser.fullName}
                        </span>
                      </div>
                    ))}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Completed By
                </Form.Label>
                <Col sm="6">
                  {isAdmin && (
                    <Form.Control
                      placeholder="Search Employee"
                      value={formik.values.completedEmployeeName}
                      onBlur={handleCompletedEmployeeBlur}
                      onChange={(e) => handleCompletedEmployeeChange(e)}
                      readOnly={Boolean(formik.values.completedEmployeeId)}
                      className={'form-control form-control-sm'}
                    />
                  )}

                  {completedEmployeesData && displayCompletedEmployeeResults ? (
                    <InfiniteScroll
                      height={100}
                      dataLength={
                        completedEmployeesData?.employees?.edges.length
                      }
                      hasMore={
                        completedEmployeesData?.employees?.pageInfo.hasNextPage
                      }
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {completedEmployeesData?.employees?.edges.map(
                            (employee) => {
                              return (
                                <tr
                                  onMouseDown={() =>
                                    handleCompletedEmployeeClick(employee)
                                  }
                                  key={employee.node.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small>
                                      {employee.node.gaiaUser.fullName}
                                    </small>
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  ) : null}
                </Col>
                {formik.values.completedEmployeeId && isAdmin && (
                  <Col>
                    <span
                      type="button"
                      className="btn-link"
                      onClick={handleCompletedEmployeeXClick}
                    >
                      <Trash />
                    </span>
                  </Col>
                )}
              </Form.Group>
              {!objFull(_updateTask) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Files
                  </Form.Label>

                  <Col>
                    <span
                      onClick={() => {
                        if (isAdmin) {
                          formik.setFieldValue(
                            `sharedCanSeeFiles`,
                            !formik.values.sharedCanSeeFiles
                          )
                        }
                      }}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                      }}
                    >
                      <Form.Check
                        name="sharedCanSeeFiles"
                        type="switch"
                        disabled={!isAdmin}
                        label="Contacts Can See Files"
                        checked={formik.values.sharedCanSeeFiles}
                        style={{
                          fontSize: '1rem',
                          color: '#333',
                        }}
                      />
                    </span>
                  </Col>

                  {formik.values.sharedCanSeeFiles && (
                    <>
                      <Col>
                        <span
                          onClick={() => {
                            if (isAdmin) {
                              formik.setFieldValue(
                                `sharedCanCreateFiles`,
                                !formik.values.sharedCanCreateFiles
                              )
                            }
                          }}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: '10px 20px',
                          }}
                        >
                          <Form.Check
                            name="sharedCanCreateFiles"
                            type="switch"
                            label="Contacts Can Create Files"
                            checked={formik.values.sharedCanCreateFiles}
                            disabled={!isAdmin}
                            style={{
                              fontSize: '1rem',
                              color: '#333',
                            }}
                          />
                        </span>
                      </Col>

                      <Col>
                        <span
                          onClick={() => {
                            if (isAdmin) {
                              formik.setFieldValue(
                                `sharedCanCreateFolders`,
                                !formik.values.sharedCanCreateFolders
                              )
                            }
                          }}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: '10px 20px',
                          }}
                        >
                          <Form.Check
                            name="sharedCanCreateFolders"
                            type="switch"
                            label="Contacts Can Create Folders"
                            checked={formik.values.sharedCanCreateFolders}
                            disabled={!isAdmin}
                            style={{
                              fontSize: '1rem',
                              color: '#333',
                            }}
                          />
                        </span>
                      </Col>
                    </>
                  )}
                </Form.Group>
              )}

              {objFull(_updateTask) && (
                <>
                  <Tabs
                    activeKey={activeTab}
                    onSelect={(tab) => {
                      setActiveTab(tab)
                    }}
                  >
                    <Tab
                      key="Files"
                      eventKey="Files"
                      style={{ marginTop: '10px' }}
                      title={
                        <b>
                          <FileEarmarkText className="mr-2" />
                          Files
                        </b>
                      }
                    >
                      <>
                        {activeTab == 'Files' && (
                          <>
                            {isEmployee && (
                              <Form.Group as={Row}>
                                <Col>
                                  <span
                                    onClick={() => {
                                      if (isAdmin) {
                                        formik.setFieldValue(
                                          `sharedCanSeeFiles`,
                                          !formik.values.sharedCanSeeFiles
                                        )
                                      }
                                    }}
                                    style={{
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                      padding: '10px 20px',
                                    }}
                                  >
                                    <Form.Check
                                      name="sharedCanSeeFiles"
                                      type="switch"
                                      className="form-control-sm"
                                      label="Contacts Can See Files"
                                      checked={formik.values.sharedCanSeeFiles}
                                      disabled={!isAdmin}
                                    />
                                  </span>
                                </Col>

                                {formik.values.sharedCanSeeFiles && (
                                  <>
                                    <Col>
                                      <span
                                        onClick={() => {
                                          if (isAdmin) {
                                            formik.setFieldValue(
                                              `sharedCanCreateFiles`,
                                              !formik.values
                                                .sharedCanCreateFiles
                                            )
                                          }
                                        }}
                                        style={{
                                          display: 'inline-flex',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                          padding: '10px 20px',
                                        }}
                                      >
                                        <Form.Check
                                          name="sharedCanCreateFiles"
                                          type="switch"
                                          className="form-control-sm"
                                          label="Contacts Can Create Files"
                                          checked={
                                            formik.values.sharedCanCreateFiles
                                          }
                                          disabled={!isAdmin}
                                          style={{
                                            fontSize: '1rem',
                                            color: '#333',
                                          }}
                                        />
                                      </span>
                                    </Col>

                                    <Col>
                                      <span
                                        onClick={() => {
                                          if (isAdmin) {
                                            formik.setFieldValue(
                                              `sharedCanCreateFolders`,
                                              !formik.values
                                                .sharedCanCreateFolders
                                            )
                                          }
                                        }}
                                        style={{
                                          display: 'inline-flex',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                          padding: '10px 20px',
                                        }}
                                      >
                                        <Form.Check
                                          name="sharedCanCreateFolders"
                                          type="switch"
                                          className="form-control-sm"
                                          label="Contacts Can Create Folders"
                                          checked={
                                            formik.values.sharedCanCreateFolders
                                          }
                                          disabled={!isAdmin}
                                          style={{
                                            fontSize: '1rem',
                                            color: '#333',
                                          }}
                                        />
                                      </span>
                                    </Col>
                                  </>
                                )}
                              </Form.Group>
                            )}
                            <FilesTable
                              taskId={_updateTask.id}
                              sharedCanCreateFiles={
                                _updateTask.sharedCanCreateFiles
                              }
                              sharedCanCreateFolders={
                                _updateTask.sharedCanCreateFolders
                              }
                              folderId={
                                !isEmployee
                                  ? _updateTask.sharedFolder?.id
                                  : _updateTask.folder?.id
                              }
                              tableHeight={200}
                              fetchPolicy="no-cache"
                            />
                          </>
                        )}
                      </>
                    </Tab>
                    <Tab
                      key="Notifications"
                      eventKey="Notifications"
                      style={{ marginTop: '10px' }}
                      title={
                        <b>
                          <Bell className="mr-2" />
                          Notifications
                        </b>
                      }
                    >
                      {taskNotificationTriggerId && (
                        <NotificationSender
                          taskId={_updateTask.id}
                          notificationTriggerId={taskNotificationTriggerId}
                          employeeSearch
                          gaiaUserSearchMultiple
                          employeeSearchMultiple
                          gaiaUserSearch
                          gaiaUserSearchQuery={gql`
                            query GaiaUsers(
                              $first: Int
                              $after: String
                              $search: String
                              $tasks: [ID]
                            ) {
                              gaiaUsers(
                                first: $first
                                after: $after
                                tasks: $tasks
                                orderBy: "full_name"
                                search: $search
                              ) {
                                nodeCount
                                pageInfo {
                                  hasNextPage
                                  endCursor
                                }
                                edges {
                                  node {
                                    id
                                    fullName
                                    email
                                  }
                                }
                              }
                            }
                          `}
                          gaiaUserSearchVariables={{
                            tasks: [_updateTask.id],
                          }}
                        />
                      )}
                    </Tab>
                    {isAdmin && (
                      <Tab
                        key="History"
                        eventKey="History"
                        style={{ marginTop: '10px' }}
                        title={
                          <b>
                            <ClockHistory className="mr-2" />
                            History
                          </b>
                        }
                      >
                        {activeTab === 'History' && (
                          <AuditLog
                            tableHeight={300}
                            contentType={_updateTask.contentType.model}
                            id={_updateTask.id}
                          />
                        )}
                      </Tab>
                    )}
                  </Tabs>
                </>
              )}
              <Row>
                <Col sm="3">
                  <Button
                    type="submit"
                    block
                    size="sm"
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>

                {objFull(_updateTask) && isAdmin && (
                  <>
                    <Col sm="3">
                      <Button
                        block
                        variant="outline-danger"
                        onClick={deleteMutation}
                      >
                        <Trash className="mr-2" />
                        Delete
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default TaskModal
