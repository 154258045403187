// StripeIntegration.js
import React, { useState } from 'react'
import { Badge, Button, Form, Row, Col } from 'react-bootstrap'
import { Plug, Cash, Trash } from 'react-bootstrap-icons'
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client'
import IntegrationCard from './IntegrationCard'
import toast from 'react-hot-toast'

const StripeIntegration = () => {
  const [connectedAccountId, setConnectedAccountId] = useState('')

  const [updateStripeConnector] = useMutation(
    gql`
      mutation UpdateStripeConnector($input: UpdateStripeConnectorInput!) {
        updateStripeConnector(input: $input) {
          stripeConnector {
            id
            connectedAccountId
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (connectedAccountId) {
          if (data.updateStripeConnector.stripeConnector.connectedAccountId) {
            toast.success('Stripe Enabled')
            setConnectedAccountId('')
          } else {
            toast.error('Invalid Account ID')
          }
        } else {
          toast.success('Stripe Disabled')
        }
      },
      refetchQueries: ['StripeConnectorQuery'],
    }
  )

  const [stripeConnectedAccountUrlQuery] = useLazyQuery(
    gql`
      query StripeConnectedAccountQuery {
        stripeConnectedAccountUrl {
          url
        }
      }
    `,
    {
      onCompleted: (data) => {
        window.open(data.stripeConnectedAccountUrl.url, '_blank')
      },
    }
  )

  const [stripeExpressDashboardUrlQuery] = useLazyQuery(
    gql`
      query StripeExpressDashboardQuery {
        stripeExpressDashboardUrl {
          url
        }
      }
    `,
    {
      onCompleted: (data) => {
        window.open(data.stripeExpressDashboardUrl.url, '_blank')
      },
    }
  )

  const { data, error } = useQuery(
    gql`
      query StripeConnectorQuery {
        stripeConnectors {
          edges {
            node {
              id
              connectedAccountId
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  if (!data) return null
  if (error) return <div>Error loading Stripe integration.</div>

  const stripeConnector = data.stripeConnectors.edges[0].node
  const stripeEnabled = !!stripeConnector.connectedAccountId

  const handleConnectStripeClick = () => {
    toast.success('Opening Stripe')
    stripeConnectedAccountUrlQuery()
  }

  const handleStripeDashboardClick = () => {
    toast.success('Opening Stripe Dashboard')
    stripeExpressDashboardUrlQuery()
  }

  return (
    <IntegrationCard
      logo={`${window.location.origin}/stripe-logo.png`}
      name="Stripe"
      description="Integrate Stripe to securely process booking fees and invoices. Manage payments and financial transactions efficiently through Stripe's platform."
    >
      <Row>
        <Col>
          {stripeEnabled ? (
            <Badge variant="success">Enabled</Badge>
          ) : (
            <Badge variant="danger">Disabled</Badge>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {stripeEnabled ? (
            <>
              <Button variant="link" onClick={handleStripeDashboardClick}>
                <Cash className="mr-2" /> Stripe Dashboard
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  updateStripeConnector({
                    variables: {
                      input: {
                        stripeConnectorInput: {
                          id: stripeConnector.id,
                          connectedAccountId: null,
                        },
                      },
                    },
                  })
                }}
              >
                <Trash className="mr-2" /> Disable Stripe
              </Button>
            </>
          ) : (
            <>
              <Button variant="link" onClick={handleConnectStripeClick}>
                <Cash className="mr-2" /> Connect Stripe
              </Button>
              <Form inline className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="Stripe Connected Account ID"
                  value={connectedAccountId}
                  onChange={(e) => setConnectedAccountId(e.target.value)}
                  className="mr-2"
                />
                <Button
                  variant="primary"
                  onClick={() => {
                    updateStripeConnector({
                      variables: {
                        input: {
                          stripeConnectorInput: {
                            id: stripeConnector.id,
                            connectedAccountId,
                          },
                        },
                      },
                    })
                  }}
                >
                  <Plug className="mr-2" /> Save
                </Button>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </IntegrationCard>
  )
}

export default StripeIntegration
