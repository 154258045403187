import React, { useState } from 'react'
import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'
import { useLocation } from 'react-router-dom'
import { useImagequixEnabled } from '../../libs/imagequix'
import { v4 as uuidv4 } from 'uuid'
import { Row, Col } from 'react-bootstrap'
import Loading from '../common/Loading'
import { US_STATES, US_STATES_MAP } from '../../libs/constant'

export default function ImporterWidget(props) {
  const {
    setRowFatalError,
    rowFatalError,
    setRowsMissingInfo,
    updateCsvData,
    importerKey,
    fotomerchantPassword,
  } = props
  const [transformedData, setTransformedData] = useState([])
  const [validating, setValidating] = useState(false)
  const iqEnabled = useImagequixEnabled()
  const location = useLocation()
  const orgForm = location.pathname.includes('/organization')
  const hideImagequix = orgForm || !iqEnabled
  const validateMetadata = (str) => {
    let metadata = {}
    const keyValues = str.split('|')
    keyValues.forEach((keyValue) => {
      let splitKeyValue = keyValue.split(':')
      if (splitKeyValue.length !== 2) {
        return false
      } else {
        metadata[splitKeyValue[0]] = splitKeyValue[1]
      }
    })
    return metadata
  }

  const validatePhone = (phone) => {
    let cleanPhone = phone.replace(/[^\w]/g, '')
    if (cleanPhone.length == 10) {
      cleanPhone = `${1}${cleanPhone}`
    }
    if (isNaN(parseInt(cleanPhone)) || cleanPhone.charAt(0) != 1) {
      cleanPhone = false
    }
    return cleanPhone
  }
  return (
    <>
      <Importer
        key={importerKey}
        defaultNoHeader={false}
        restartable={false}
        dataHandler={(rows, { startIndex }) => {
          const batchTransformedData = []
          let allErrors = []
          const uuidRows = {}
          const seenEmails = []
          rows.forEach((row, i) => {
            const errors = []
            let subjectPhone
            let subjectSecondaryPhone
            let parentOnePhone
            let parentOneSecondaryPhone
            let parentTwoPhone
            let parentTwoSecondaryPhone
            let metadata = {}
            let sgMetadata = {}
            let fatal = false
            const uuid = uuidv4()
            if (row.subjectMetadata) {
              metadata = validateMetadata(row.subjectMetadata)
              if (!metadata) {
                errors.push({
                  fatal: true,
                  row: startIndex + i + 1,
                  column: 'Subject Metadata',
                  error:
                    'Metadata must follow this format key1:value1|key2:value2|key3:value3',
                })
                fatal = true
                if (!rowFatalError) {
                  setRowFatalError(true)
                }
              }
            }
            if (row.subjectGroupMetadata) {
              sgMetadata = validateMetadata(row.subjectGroupMetadata)
              if (!sgMetadata) {
                errors.push({
                  fatal: true,
                  row: startIndex + i + 1,
                  column: 'Subject Group Metadata',
                  error:
                    'Metadata must follow this format key1:value1|key2:value2|key3:value3',
                })
                fatal = true
                if (!rowFatalError) {
                  setRowFatalError(true)
                }
              }
            }
            if (row.studentId && !row.subjectLastName) {
              errors.push({
                fatal: true,
                row: startIndex + i + 1,
                column: 'Subject Last Name',
                error: 'Required for students to login',
              })
              fatal = true
              if (!rowFatalError) {
                setRowFatalError(true)
              }
            }
            let state = row.state
              ? row.state.charAt(0).toUpperCase() + row.state.slice(1)
              : null
            if (state && state.length === 2) {
              state = state.toUpperCase()
              if (US_STATES_MAP[state]) {
                state = US_STATES_MAP[state]
              }
            }
            if (state && !US_STATES.includes(state)) {
              errors.push({
                fatal: true,
                row: startIndex + i + 1,
                column: 'State',
                error: 'Must be full name of US state (Illinois not IL) ',
              })
              fatal = true
              if (!rowFatalError) {
                setRowFatalError(true)
              }
            }
            if (row.subjectPhone) {
              subjectPhone = validatePhone(row.subjectPhone)
              if (!subjectPhone) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Subject Phone Number',
                  error:
                    'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                })
              }
            }
            if (row.subjectSecondaryPhone) {
              subjectSecondaryPhone = validatePhone(row.subjectSecondaryPhone)
              if (!subjectSecondaryPhone) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Subject Secondary Phone Number',
                  error:
                    'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                })
              }
            }
            if (row.parentOnePhone) {
              parentOnePhone = validatePhone(row.parentOnePhone)
              if (!parentOnePhone) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Parent Phone Number',
                  error:
                    'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                })
              }
            }
            if (row.parentOneSecondaryPhone) {
              parentOneSecondaryPhone = validatePhone(
                row.parentOneSecondaryPhone
              )
              if (!parentOneSecondaryPhone) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Contact One Secondary Phone Number',
                  error:
                    'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                })
              }
            }
            if (row.parentTwoPhone) {
              parentTwoPhone = validatePhone(row.parentTwoPhone)
              if (!parentTwoPhone) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Contact Two Phone Number',
                  error:
                    'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                })
              }
            }
            if (row.parentTwoSecondaryPhone) {
              parentTwoSecondaryPhone = validatePhone(
                row.parentTwoSecondaryPhone
              )
              if (!parentTwoSecondaryPhone) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Contact Two Secondary Phone Number',
                  error:
                    'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                })
              }
            }
            if (row.subjectEmail) {
              if (seenEmails.includes(row.subjectEmail)) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Subject Email',
                  error:
                    'This email was found assocaited to another subject email in the upload. If this is a parent email, map it to that column not subject email. Otherwise this subject will not be assigned and email and must login with last name and ID number.',
                })
                row.subjectEmail = null
              } else {
                seenEmails.push(row.subjectEmail)
              }
            }
            const subject = {
              uuid,
              subjectEmail: row.subjectEmail,
              subjectPassword: row.subjectPassword,
              subjectFirstName: row.subjectFirstName
                ? row.subjectFirstName
                : null,
              subjectLastName: row.subjectLastName ? row.subjectLastName : null,
              subjectSecondaryEmail: row.subjectSecondaryEmail
                ? row.subjectSecondaryEmail
                : null,
              studentId: row.studentId ? row.studentId : null,
              subjectPhone: row.subjectPhone ? subjectPhone : null,
              subjectSecondaryPhone: row.subjectSecondaryPhone
                ? subjectSecondaryPhone
                : null,
              addressLineOne: row.addressLineOne ? row.addressLineOne : null,
              addressLineTwo: row.addressLineTwo ? row.addressLineTwo : null,
              city: row.city ? row.city : null,
              state,
              subjectJobTitle: row.subjectJobTitle ? row.subjectJobTitle : null,
              zipCode: row.zipCode ? row.zipCode : null,
              parentOnePassword: row.parentOnePassword
                ? row.parentOnePassword
                : null,
              parentTwoPassword: row.parentTwoPassword
                ? row.parentTwoPassword
                : null,
              parentOneFirstName: row.parentOneFirstName
                ? row.parentOneFirstName
                : null,
              parentOnePreviousEmail: row.parentOnePreviousEmail
                ? row.parentOnePreviousEmail
                : null,
              parentTwoPreviousEmail: row.parentTwoPreviousEmail
                ? row.parentTwoPreviousEmail
                : null,
              parentOneLastName: row.parentOneLastName
                ? row.parentOneLastName
                : null,
              parentOnePhone: row.parentOnePhone ? parentOnePhone : null,
              parentOneSecondaryPhone: row.parentOneSecondaryPhone
                ? parentOneSecondaryPhone
                : null,
              parentOneEmail: row.parentOneEmail ? row.parentOneEmail : null,
              parentOneSecondaryEmail: row.parentOneSecondaryEmail
                ? row.parentOneSecondaryEmail
                : null,
              parentTwoFirstName: row.parentTwoFirstName
                ? row.parentTwoFirstName
                : null,
              parentTwoLastName: row.parentTwoLastName
                ? row.parentTwoLastName
                : null,
              parentTwoPhone: row.parentTwoPhone ? parentTwoPhone : null,
              parentTwoSecondaryPhone: row.parentTwoSecondaryPhone
                ? parentTwoSecondaryPhone
                : null,
              parentTwoEmail: row.parentTwoEmail ? row.parentTwoEmail : null,
              parentTwoSecondaryEmail: row.parentTwoSecondaryEmail
                ? row.parentTwoSecondaryEmail
                : null,
              metadata: JSON.stringify(metadata),
              fotomerchantPassword: row.fotomerchantPassword,
              subjectGroupMetadata: JSON.stringify(sgMetadata),
              subjectCustomOne: row.customOne,
              subjectCustomTwo: row.customTwo,
              subjectCustomThree: row.customThree,
              subjectCustomFour: row.customFour,
              subjectCustomFive: row.customFive,
              subjectGroupCustomOne: row.subjectGroupCustomOne,
              subjectGroupCustomTwo: row.subjectGroupCustomTwo,
              subjectGroupCustomThree: row.subjectGroupCustomThree,
              subjectGroupCustomFour: row.subjectGroupCustomFour,
              subjectGroupCustomFive: row.subjectGroupCustomFive,
            }
            if (!hideImagequix) {
              subject.imagequixId = row.iqId ? row.iqId : null
            }
            if (!fatal) {
              batchTransformedData.push(subject)
            }
            if (errors.length) {
              allErrors = [...allErrors, ...errors]
            }
          })
          if (allErrors.length > 0) {
            setRowsMissingInfo((prevState) => [...prevState, ...allErrors])
          }
          setTransformedData((prevState) => [
            ...prevState,
            ...batchTransformedData,
          ])
        }}
        onComplete={() => {
          updateCsvData(transformedData)
          setTransformedData([])
        }}
        skipEmptyLines
      >
        <ImporterField
          name="subjectFirstName"
          label="subject first name"
          optional
        />
        <ImporterField
          name="subjectPassword"
          label="subject password"
          optional
        />
        <ImporterField
          name="subjectLastName"
          label="subject last name"
          optional
        />
        <ImporterField
          name="subjectJobTitle"
          label="subject job title"
          optional
        />
        {!hideImagequix && (
          <ImporterField name="iqId" label="blueprint online code" optional />
        )}
        <ImporterField
          name="subjectMetadata"
          label="subject metadata"
          optional
        />
        {fotomerchantPassword && (
          <ImporterField
            name="fotomerchantPassword"
            label="fotomerchant password"
            optional
          />
        )}
        {!orgForm && (
          <ImporterField
            name="subjectGroupMetadata"
            label="subject group metadata"
            optional
          />
        )}
        <ImporterField name="subjectEmail" label="subject email" optional />
        <ImporterField
          name="subjectSecondaryEmail"
          label="subject secondary email"
          optional
        />
        <ImporterField name="studentId" label="subject ID" optional />
        <ImporterField name="subjectPhone" label="subject phone" optional />
        <ImporterField
          name="subjectSecondaryPhone"
          label="subject secondary phone"
          optional
        />

        <ImporterField
          name="addressLineOne"
          label="address line one"
          optional
        />
        <ImporterField
          name="addressLineTwo"
          label="address line two"
          optional
        />
        <ImporterField name="city" label="city" optional />
        <ImporterField name="state" label="state" optional />
        <ImporterField name="zipCode" label="zip" optional />

        <ImporterField
          name="parentOneFirstName"
          label="contact one first name"
          optional
        />
        <ImporterField
          name="parentOneLastName"
          label="contact one last name"
          optional
        />
        <ImporterField
          name="parentOnePhone"
          label="contact one phone"
          optional
        />
        <ImporterField
          name="parentOneSecondaryPhone"
          label="contact one secondary phone"
          optional
        />
        <ImporterField
          name="parentOnePreviousEmail"
          label="contact one previous email"
          optional
        />
        <ImporterField
          name="parentOneEmail"
          label="contact one email"
          optional
        />
        <ImporterField
          name="parentOnePassword"
          label="contact one password"
          optional
        />
        <ImporterField
          name="parentOneSecondaryEmail"
          label="contact one secondary email"
          optional
        />
        <ImporterField
          name="parentTwoFirstName"
          label="contact two first name"
          optional
        />
        <ImporterField
          name="parentTwoLastName"
          label="contact two last name"
          optional
        />
        <ImporterField
          name="parentTwoPhone"
          label="contact two phone"
          optional
        />
        <ImporterField
          name="parentTwoSecondaryPhone"
          label="contact two secondary phone"
          optional
        />
        <ImporterField
          name="parentTwoPreviousEmail"
          label="contact two previous email"
          optional
        />
        <ImporterField
          name="parentTwoEmail"
          label="contact two email"
          optional
        />
        <ImporterField
          name="parentTwoPassword"
          label="contact two password"
          optional
        />
        <ImporterField
          name="parentTwoSecondaryEmail"
          label="contact two secondary email"
          optional
        />
        <ImporterField name="customOne" label="subject custom one" optional />
        <ImporterField name="customTwo" label="subject custom two" optional />
        <ImporterField
          name="customThree"
          label="subject custom three"
          optional
        />
        <ImporterField name="customFour" label="subject custom four" optional />
        <ImporterField name="customFive" label="subject custom five" optional />
        <ImporterField
          name="subjectGroupCustomOne"
          label="subject group custom one"
          optional
        />
        <ImporterField
          name="subjectGroupCustomTwo"
          label="subject group custom two"
          optional
        />
        <ImporterField
          name="subjectGroupCustomThree"
          label="subject group custom three"
          optional
        />
        <ImporterField
          name="subjectGroupCustomFour"
          label="subject group custom four"
          optional
        />
        <ImporterField
          name="subjectGroupCustomFive"
          label="subject group custom five"
          optional
        />
      </Importer>
      {validating && (
        <Row className="mt-3">
          <Col>
            <Loading />
          </Col>
        </Row>
      )}
    </>
  )
}
