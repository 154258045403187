import React from 'react'
import {
  Telephone,
  Envelope,
  Globe,
  Linkedin,
  Facebook,
  Instagram,
  Twitter,
} from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import { useReactiveVar } from '@apollo/client'
import { settingsVar } from '../../libs/apollo'

const Footer = (props) => {
  const { signUp } = props
  const settings = useReactiveVar(settingsVar)
  if (!signUp && !settings) return <></>
  let {
    name,
    email,
    twitterInFooter,
    twitterUrl,
    instagramInFooter,
    instagramUrl,
    facebookInFooter,
    facebookUrl,
    linkedinInFooter,
    linkedinUrl,
    websiteUrl,
    phoneNumber,
    colorScheme,
  } = signUp ? {} : settings

  if (signUp) {
    name = 'Airstudio'
    email = 'info@airstudio.io'
    twitterInFooter = false
    instagramInFooter = false
    facebookInFooter = false
    linkedinInFooter = true
    linkedinUrl = 'https://www.linkedin.com/company/airstudio-photo'
    websiteUrl = 'https://airstudio.info'
    phoneNumber = '18474310970'
  }

  const footerItems = [
    {
      renderItem: websiteUrl,
      icon: <Globe size={10} className="me-1" />,
      value: name,
      link: websiteUrl,
    },
    {
      renderItem: email,
      icon: <Envelope size={10} className="me-1" />,
      value: email,
      link: email,
      isEmailLink: true,
    },
    {
      renderItem: twitterInFooter,
      icon: <Twitter size={10} />,
      link: twitterUrl,
    },
    {
      renderItem: instagramInFooter,
      icon: <Instagram size={10} />,
      link: instagramUrl,
    },
    {
      renderItem: facebookInFooter,
      icon: <Facebook size={10} />,
      link: facebookUrl,
    },
    {
      renderItem: linkedinInFooter,
      icon: <Linkedin size={10} />,
      link: linkedinUrl,
    },
    {
      renderItem: phoneNumber,
      icon: <Telephone size={10} className="me-1" />,
      value: `${phoneNumber.charAt(0)}-${phoneNumber.substring(
        1,
        4
      )}-${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7)}`,
      link: '',
    },
  ]
  return (
    <div
      className="d-flex align-items-start fixed-bottom"
      style={{ backgroundColor: colorScheme }}
    >
      <div style={{ marginLeft: '5%' }}>
        {footerItems.map((footerItem, index) => {
          if (footerItem.renderItem) {
            return (
              <Button
                className={[
                  'text-decoration-none',
                  'small',
                  'ps-0',
                  'me-2',
                  'd-inline-flex',
                  'align-items-center',
                ].join(' ')}
                variant="link"
                style={{ opacity: 1 }}
                onClick={() => {
                  if (footerItem.link) {
                    if (footerItem.isEmailLink) {
                      window.location.href = `mailto:${footerItem.link}`
                    } else {
                      window.open(footerItem.link, '_blank')
                    }
                  }
                }}
                key={index}
                disabled={Boolean(!footerItem.link)}
              >
                {footerItem.icon}{' '}
                {footerItem?.value && (
                  <span style={{ fontSize: '0.75rem' }}>
                    {footerItem.value}
                  </span>
                )}
              </Button>
            )
          }
        })}
      </div>
    </div>
  )
}

export default Footer
