import React, { useEffect, useRef, useState } from 'react'
import { Button, Row, Col, Form, Badge } from 'react-bootstrap'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { gql } from '@apollo/client'
import EmailNotificationTemplateBuilder from './EmailNotificationTemplateBuilder'
import SMSNotificationTemplateBuilder from './SMSNotificationTemplateBuilder'
import { convertToRaw } from 'draft-js'
import validator from 'validator'
import { PlusCircle, CaretRight, CaretDown } from 'react-bootstrap-icons'
import { useFormik } from 'formik'
import EmployeeSearch from '../common/node_search_input/EmployeeSearchInput'
import GaiaUserSearchInput from '../common/node_search_input/GaiaUserSearchInput'
import draftToHtml from 'draftjs-to-html'
import Loading from '../common/Loading'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { loggedInUserVar } from '../../libs/apollo'
import NotificationsTable from './NotificationsTable'

const NotificationSender = (props) => {
  const {
    employeeSearchMultiple,
    gaiaUserSearchMultiple,
    notificationTriggerId,
    employeeSearch,
    gaiaUserSearch,
    employeeSearchVariables,
    employeeSearchQuery,
    gaiaUserSearchQuery,
    gaiaUserSearchVariables,
    taskId,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [submitting, setSubmitting] = useState(false)
  const [reloadEmailEditorRef, setReloadEmailEditorRef] = useState(false)
  const [displayTemplateVariables, setDisplayTemplateVariables] =
    useState(false)
  const [displayNotificationsTable, setDisplayNotificationsTable] =
    useState(false)
  const emailEditorRef = useRef(null)

  const { data: myUser } = useQuery(
    gql`
      query MyUser($id: ID!) {
        myUser(id: $id) {
          employee {
            id
            smtpValid
            smtpUser
          }
        }
      }
    `,
    {
      variables: {
        id: loggedInUser.id,
      },
      fetchPolicy: 'network-only',
    }
  )

  const formik = useFormik({
    initialValues: {
      manualEmails: '',
      emailSubject: '',
      ccEmails: '',
      bccEmails: '',
      plaintextEmailEditorState: null,
      notificationEmailTemplate: null,
      notificationSMSTemplate: null,
      employees: [],
      gaiaUsers: [],
    },
    validationSchema: Yup.object({
      manualEmails: Yup.string()
        .nullable()
        .test('is-email-list', 'Invalid email(s)', (value) => {
          if (!value) return true
          const emails = value.split(',').map((email) => email.trim())
          return emails.every((email) => validator.isEmail(email))
        }),
      emailSubject: Yup.string().nullable(),
      plaintextEmailEditorState: Yup.object().nullable(),
      employees: Yup.array().of(Yup.object().nullable()),
      gaiaUsers: Yup.array().of(Yup.object().nullable()),
      ccEmails: Yup.string().nullable(),
      bccEmails: Yup.string().nullable(),
    }),
  })

  const [sendNotificationTrigger] = useMutation(
    gql`
      mutation SendNotificationTrigger(
        $sendNotificationTriggerInput: SendNotificationTriggerInput!
      ) {
        sendNotificationTrigger(input: $sendNotificationTriggerInput) {
          sent
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: () => {
        setSubmitting(false)
        toast.success('Sending Notification')
      },
      refetchQueries: ['NotificationsQuery'],
    }
  )

  const getModifiedTemplate = async () => {
    if (formik.values.plaintextEmailBuilder) {
      if (formik.values.plaintextEmailEditorState) {
        return draftToHtml(
          convertToRaw(
            formik.values.plaintextEmailEditorState.getCurrentContent()
          )
        )
      }
    } else {
      if (emailEditorRef.current?.editor) {
        const data = await new Promise((resolve) => {
          emailEditorRef.current.editor.exportHtml((data) => {
            resolve(data)
          })
        })

        return data.html.replace(/\{\{%20(.*?)%20\}\}/g, '{{$1}}')
      }
    }
    return ''
  }

  const [notificationTriggerQuery, { data: notificationTrigger }] =
    useLazyQuery(
      gql`
        query NotificationTrigger($id: ID!) {
          notificationTrigger(id: $id) {
            id
            name
            notificationTemplateChannels {
              edges {
                node {
                  id
                  channelType
                  notificationTemplate {
                    id
                    name
                    bccEmails
                    file {
                      fileName
                    }
                    ccEmails
                    emailSubject
                    emailTemplateDesign
                    smsContent
                    dynamicContext
                    images
                    plaintextEmailBuilder
                    plaintextEmail
                  }
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          data.notificationTrigger.notificationTemplateChannels.edges.forEach(
            (notificationTemplateChannel) => {
              if (notificationTemplateChannel.node.channelType === 'Email') {
                formik.setFieldValue(
                  'notificationEmailTemplate',
                  notificationTemplateChannel.node.notificationTemplate
                )
                if (
                  notificationTemplateChannel.node.notificationTemplate
                    .plaintextEmailBuilder
                ) {
                  formik.setFieldValue('plaintextEmailBuilder', true)
                }
                formik.setFieldValue(
                  'emailSubject',
                  notificationTemplateChannel.node.notificationTemplate
                    .emailSubject
                )
              } else if (
                notificationTemplateChannel.node.channelType === 'SMS'
              ) {
                formik.setFieldValue(
                  'notificationSMSTemplate',
                  notificationTemplateChannel.node.notificationTemplate
                )
              }
            }
          )
        },
      }
    )

  useEffect(() => {
    if (notificationTriggerId) {
      notificationTriggerQuery({ variables: { id: notificationTriggerId } })
    }
  }, [notificationTriggerId])

  if (!notificationTrigger?.notificationTrigger)
    return (
      <div className="mt-3 mb-3">
        <Loading />
      </div>
    )

  const dynamicTemplateVariables = notificationTrigger?.notificationTrigger
    ?.notificationTemplateChannels?.edges[0]?.node.notificationTemplate
    .dynamicContext
    ? JSON.parse(
        notificationTrigger?.notificationTrigger?.notificationTemplateChannels
          ?.edges[0]?.node.notificationTemplate.dynamicContext
      )
    : {}
  return (
    <>
      <Row className="mb-2">
        <Col sm={2} md="auto">
          <Button
            variant="link"
            disabled={
              (!formik.values.employees.length &&
                !formik.values.gaiaUsers.length &&
                !formik.values.manualEmails) ||
              submitting
            }
            onClick={async () => {
              const modifiedTemplate = await getModifiedTemplate()
              if (!modifiedTemplate.includes('{{ unsubscribe_link }}')) {
                toast.error(
                  'Email template must include {{ unsubscribe_link }}'
                )
                return
              }
              setSubmitting(true)
              sendNotificationTrigger({
                variables: {
                  sendNotificationTriggerInput: {
                    notificationTriggerInput: {
                      id: notificationTriggerId,
                      employeeIds: formik.values.employees.map(
                        (employee) => employee.id
                      ),
                      userIds: formik.values.gaiaUsers.map(
                        (gaiaUser) => gaiaUser.id
                      ),
                      emails: formik.values.manualEmails,
                      channel: 'Email',
                      ccEmails: formik.values.ccEmails,
                      bccEmails: formik.values.bccEmails,
                      modifiedTemplate,
                      taskId,
                    },
                  },
                },
              })
            }}
          >
            <PlusCircle className="mr-2" />
            Send Email
          </Button>
        </Col>
        {myUser.myUser.employee.smtpValid && (
          <Col sm={2} md="auto">
            <Button
              variant="link"
              disabled={
                (!formik.values.employees.length &&
                  !formik.values.gaiaUsers.length &&
                  !formik.values.manualEmails) ||
                submitting
              }
              onClick={async () => {
                const modifiedTemplate = await getModifiedTemplate()
                if (!modifiedTemplate.includes('{{ unsubscribe_link }}')) {
                  toast.error(
                    'Email template must include {{ unsubscribe_link }}'
                  )
                  return
                }
                setSubmitting(true)
                sendNotificationTrigger({
                  variables: {
                    sendNotificationTriggerInput: {
                      notificationTriggerInput: {
                        id: notificationTriggerId,
                        senderEmployeeId: myUser.myUser.employee.id,
                        employeeIds: formik.values.employees.map(
                          (employee) => employee.id
                        ),
                        userIds: formik.values.gaiaUsers.map(
                          (gaiaUser) => gaiaUser.id
                        ),
                        emails: formik.values.manualEmails,
                        channel: 'Email',
                        ccEmails: formik.values.ccEmails,
                        bccEmails: formik.values.bccEmails,
                        modifiedTemplate,
                        taskId,
                      },
                    },
                  },
                })
              }}
            >
              <PlusCircle className="mr-2" />
              Send Email From Your Email
            </Button>
          </Col>
        )}
        <Col sm={3} md="auto">
          <Button
            variant="link"
            disabled={
              !formik.values.employees.length && !formik.values.gaiaUsers.length
            }
            onClick={async () => {
              if (
                !formik.values.smsContent.includes('{{ unsubscribe_link }}')
              ) {
                toast.error('SMS template must include {{ unsubscribe_link }}')
                return
              }
              setSubmitting(true)
              sendNotificationTrigger({
                variables: {
                  sendNotificationTriggerInput: {
                    notificationTriggerInput: {
                      id: notificationTriggerId,
                      employeeIds: formik.values.employees.map(
                        (employee) => employee.id
                      ),
                      userIds: formik.values.gaiaUsers.map(
                        (gaiaUser) => gaiaUser.id
                      ),
                      channel: 'SMS',
                      modifiedTemplate: formik.values.smsContent,
                      taskId,
                    },
                  },
                },
              })
            }}
          >
            <PlusCircle className="mr-2" />
            Send SMS
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Recipients</h5>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Control
            type="text"
            placeholder="Enter Emails Separated by Commas"
            value={formik.values.manualEmails}
            onChange={(e) =>
              formik.setFieldValue('manualEmails', e.target.value)
            }
            className="form-control-sm"
          />
          {formik.errors.manualEmails && (
            <span className="text-danger">
              <small>{formik.errors.manualEmails}</small>
            </span>
          )}
        </Col>
      </Row>
      {employeeSearch && (
        <Row className="mt-2">
          <Col sm={4}>
            <EmployeeSearch
              formik={formik}
              multiple={employeeSearchMultiple}
              query={employeeSearchQuery}
              variables={employeeSearchVariables}
            />
          </Col>
        </Row>
      )}
      {gaiaUserSearch && (
        <Row className="mt-2">
          <Col sm={4}>
            <GaiaUserSearchInput
              formik={formik}
              multiple={gaiaUserSearchMultiple}
              searchDescription="Contacts"
              variables={gaiaUserSearchVariables}
              query={gaiaUserSearchQuery}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col className="d-flex align-items-center">
          <button
            type="button"
            onClick={() =>
              setDisplayTemplateVariables(!displayTemplateVariables)
            }
            className="px-0 btn-link mr-1"
            style={{ marginTop: '-10px' }}
          >
            <>
              {displayTemplateVariables ? (
                <>
                  <CaretDown size={20} />
                </>
              ) : (
                <>
                  <CaretRight size={20} />
                </>
              )}
            </>
          </button>
          <h5>Template Variables</h5>
        </Col>
      </Row>
      {dynamicTemplateVariables && displayTemplateVariables && (
        <Row>
          {Object.keys(dynamicTemplateVariables).map(
            (dynamicTemplateVariable) => {
              if (
                !dynamicTemplateVariable.includes('session') &&
                !dynamicTemplateVariable.includes('subject_group')
              ) {
                const displayVariable = `{{ ${dynamicTemplateVariable} }}`
                return (
                  <Col key={dynamicTemplateVariable} xs={12} md={3}>
                    <Badge
                      style={{
                        color: 'black',
                      }}
                    >
                      {displayVariable}
                    </Badge>
                  </Col>
                )
              }
            }
          )}
        </Row>
      )}
      <EmailNotificationTemplateBuilder
        notificationTemplate={formik.values.notificationEmailTemplate}
        emailEditorRef={emailEditorRef}
        formik={formik}
        notificationSender
        setReloadEmailEditorRef={setReloadEmailEditorRef}
        reloadEmailEditorRef={reloadEmailEditorRef}
        show
      />
      {formik.values.notificationSMSTemplate && (
        <SMSNotificationTemplateBuilder
          notificationTemplate={formik.values.notificationSMSTemplate}
          formik={formik}
          show
        />
      )}
      <Row className="mt-4">
        <Col className="d-flex align-items-center">
          <button
            type="button"
            onClick={() =>
              setDisplayNotificationsTable(!displayNotificationsTable)
            }
            className="px-0 btn-link mr-1"
            style={{ marginTop: '-10px' }}
          >
            <>
              {displayNotificationsTable ? (
                <>
                  <CaretDown size={20} />
                </>
              ) : (
                <>
                  <CaretRight size={20} />
                </>
              )}
            </>
          </button>
          <h5>Sent Notifications</h5>
        </Col>
      </Row>
      {displayNotificationsTable && <NotificationsTable taskId={taskId} />}
    </>
  )
}

export default NotificationSender
