import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { Formik } from 'formik'
import produce from 'immer'
import 'react-phone-input-2/lib/style.css'
import OrgForm from './OrgForm'
import toast from 'react-hot-toast'
import { client } from '../../libs/apollo'
import { useAWSS3 } from '../../libs/aws'
import validator from 'validator'
import Loading from '../common/Loading'
import { Buildings } from 'react-bootstrap-icons'
import { useFolderConfiguration } from '../../libs/folderConfiguration'

export default function OrgModal(props) {
  const {
    showOrgModal,
    orgModalShowChange,
    updatingOrg,
    orgId,
    isOrgCreated,
    setIsSubmitting,
    isSubmitting,
    onComplete,
  } = props

  const [logoImage, setLogoImage] = useState()
  const [csvSubjects, setCsvSubjects] = useState([])
  const [bankAccounts, setBankAccounts] = useState([])
  const [csvRecordSync, setCsvRecordSync] = useState()
  const folderConfiguration = useFolderConfiguration()

  const awsS3 = useAWSS3()

  const [getOrg, { data: getOrgData }] = useLazyQuery(
    gql`
      query OrganizationDetail($organizationId: ID!) {
        organization(id: $organizationId) {
          id
          category
          name
          notes
          metadata
          invoiceEmail
          organizationStage {
            id
            name
          }
          accountManager {
            id
            gaiaUser {
              firstName
              lastName
              fullName
            }
          }
          fotomerchantClient {
            fotomerchantId
          }
          tasks {
            edges {
              node {
                id
                description
                notes
                dueDate
                eventDate
                finishedDate
                status
                taskCollection {
                  name
                  id
                }
                employees {
                  edges {
                    node {
                      id
                      gaiaUser {
                        id
                        fullName
                        email
                      }
                    }
                  }
                }
                contacts {
                  edges {
                    node {
                      id
                      fullName
                      email
                    }
                  }
                }
              }
            }
          }
          stripeCustomer {
            stripePaymentMethods {
              edges {
                node {
                  id
                  stripeResource
                }
              }
            }
          }
          locations {
            edges {
              node {
                id
                name
                archived
                addressLineOne
                addressLineTwo
                city
                state
                zipCode
                billingAddress
                shippingAddress
                archived
              }
            }
          }
          resitFeePaidSessions
          resitFeeFreeSessions
          logoImage {
            defaultImageName
          }
          stripeCustomer {
            stripePaymentMethods {
              edges {
                node {
                  stripeResource
                  created
                }
              }
            }
          }
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          subjectSharedCanSeeFiles
          subjectSharedCanCreateFiles
          subjectSharedCanCreateFolders
          subjectGroupSharedCanSeeFiles
          subjectGroupSharedCanCreateFiles
          subjectGroupSharedCanCreateFolders
          taskSharedCanSeeFiles
          taskSharedCanCreateFiles
          taskSharedCanCreateFolders
          sessionPackageBackgrounds {
            edges {
              node {
                id
                backgroundColor
                sessionPackage {
                  id
                  title
                  price
                  description
                }
              }
            }
          }
          notes
          refundPolicy
          applyNoShowPolicyFree
          applyNoShowPolicyPaid
          applyPolicyFree
          applyPolicyPaid
          timeRefundHours
          timeRefundFee
          timeRefundSessionPackageCost
          resitsIncludeFreeSessions
          noShowFee
          resitFee
          freePackageResitFee
          usingNoShowPolicy
          usingCancellationPolicy
          usingResitPolicy
          contacts {
            edges {
              node {
                id
                firstName
                lastName
                email
                phoneNumber
                secondaryPhoneNumber
                secondaryEmail
                notes
                emailNotificationsEnabled
                smsNotificationsEnabled
                jobTitle
              }
            }
          }
          tags {
            id
            name
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const innerOnHide = () => {
    orgModalShowChange()
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (orgId) {
      getOrg({
        variables: {
          organizationId: orgId,
        },
      })
    }
  }, [orgId])

  useEffect(() => {
    if (getOrgData && awsS3.client) {
      if (getOrgData.organization.logoImage) {
        getS3Object(getOrgData.organization.logoImage.defaultImageName)
      }
    }
  }, [getOrgData, awsS3.client])

  const [processOrganizationCsv] = useMutation(
    gql`
      mutation ProcessOrganizationCsv(
        $processOrganizationCsvInput: ProcessOrganizationCsvInput!
      ) {
        processOrganizationCsv(input: $processOrganizationCsvInput) {
          organization {
            id
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const [updateOrganization, { error: updateOrgError }] = useMutation(
    gql`
      mutation UpdateOrganizationMutation(
        $updateOrganizationInput: UpdateOrganizationInput!
      ) {
        updateOrganization(input: $updateOrganizationInput) {
          organization {
            id
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setIsSubmitting(false)
        if (!updateOrgError) {
          if (csvSubjects.length > 0) {
            processOrganizationCsv({
              variables: {
                processOrganizationCsvInput: {
                  organizationInput: {
                    id: data.updateOrganization.organization.id,
                    recordSync: csvRecordSync,
                    csvSubjects,
                  },
                },
              },
            })
          }
          orgModalShowChange()
          toast.success('Organization Saved')
          if (onComplete) {
            onComplete(data)
          }
        }
      },
      onError(error) {
        setIsSubmitting(false)
        toast.error('An Error Occured')
      },
      refetchQueries: [
        'OrganizationDetail',
        'Locations',
        'NotificationsQuery',
        'OrgContacts',
      ],
    }
  )

  const [createOrg, { error: orgError }] = useMutation(
    gql`
      mutation CreateOrganizationMutations(
        $createOrganizationInput: CreateOrganizationInput!
      ) {
        createOrganization(input: $createOrganizationInput) {
          organization {
            id
            name
            name
            contacts {
              edges {
                node {
                  id
                  email
                  fullName
                  phoneNumber
                }
              }
            }
            subjectGroups {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setIsSubmitting(false)
        if (!orgError) {
          if (csvSubjects.length > 0) {
            processOrganizationCsv({
              variables: {
                processOrganizationCsvInput: {
                  organizationInput: {
                    id: data.createOrganization.organization.id,
                    csvSubjects,
                  },
                },
              },
            })
          }
          orgModalShowChange()
          if (onComplete) {
            onComplete(data)
          }
          toast.success('Organization Created')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['Organizations', 'OrgContacts'],
    }
  )

  useEffect(() => {
    if (getOrgData?.organization?.stripeCustomer) {
      setBankAccounts(() => {
        const accounts = []
        getOrgData.organization?.stripeCustomer.stripePaymentMethods.edges.map(
          (edge) => {
            const { node } = edge
            const stripeResource = JSON.parse(node.stripeResource)
            if (stripeResource?.type === 'us_bank_account') {
              accounts.push({
                bank: stripeResource.us_bank_account.bank_name,
                accountType: stripeResource.us_bank_account.account_type,
                accountLast4: stripeResource.us_bank_account.last4,
              })
            }
          }
        )
        return accounts
      })
    }
  }, [getOrgData])

  if ((updatingOrg && !getOrgData) || !folderConfiguration) return <></>
  const transformedLocations = !getOrgData
    ? []
    : getOrgData.organization.locations.edges.map((location) => {
        const { node } = location
        return {
          name: node.name,
          id: node.id,
          addressLineOne: node.addressLineOne,
          addressLineTwo: node.addressLineTwo,
          city: node.city,
          zipCode: node.zipCode,
          state: node.state,
          shippingAddress: node.shippingAddress,
          billingAddress: node.billingAddress,
        }
      })

  const transformedContacts = !getOrgData
    ? []
    : getOrgData.organization.contacts.edges.map((contact) => {
        const { node } = contact
        return {
          id: node.id,
          firstName: node.firstName,
          lastName: node.lastName,
          email: node.email,
          secondaryEmail: node.secondaryEmail,
          phoneNumber: node.phoneNumber,
          secondaryPhoneNumber: node.secondaryPhoneNumber,
          smsNotificationsEnabled: node.smsNotificationsEnabled,
          emailNotificationsEnabled: node.emailNotificationsEnabled,
          notes: node.notes,
          jobTitle: node.jobTitle,
          password: '',
          confirmPassword: '',
        }
      })

  const transformedSessionPackageBackgrounds = !getOrgData
    ? []
    : getOrgData.organization.sessionPackageBackgrounds.edges.map((spb) => {
        const { node } = spb
        return {
          id: node.id,
          title: node.sessionPackage.title,
          sessionPackageId: node.sessionPackage.id,
          backgroundColor: node.backgroundColor,
        }
      })
  const transformCategory = (category) => {
    if (category === 'SCHOOL') {
      category = 'School'
    } else if (category === 'COMMERCIAL') {
      category = 'Commercial'
    } else if (category === 'OTHER') {
      category = 'Other'
    }
    return category
  }

  const orgInitialValues = updatingOrg
    ? {
        sharedCanSeeFiles: getOrgData?.organization.sharedCanSeeFiles,
        sharedCanCreateFiles: getOrgData?.organization.sharedCanCreateFiles,
        sharedCanCreateFolders: getOrgData?.organization.sharedCanCreateFolders,
        subjectSharedCanSeeFiles:
          getOrgData?.organization.subjectSharedCanSeeFiles,
        subjectSharedCanCreateFiles:
          getOrgData?.organization.subjectSharedCanCreateFiles,
        subjectSharedCanCreateFolders:
          getOrgData?.organization.subjectSharedCanCreateFolders,
        subjectGroupSharedCanSeeFiles:
          getOrgData?.organization.subjectGroupSharedCanSeeFiles,
        subjectGroupSharedCanCreateFiles:
          getOrgData?.organization.subjectGroupSharedCanCreateFiles,
        subjectGroupSharedCanCreateFolders:
          getOrgData?.organization.subjectGroupSharedCanCreateFolders,
        taskSharedCanSeeFiles: getOrgData?.organization.taskSharedCanSeeFiles,
        taskSharedCanCreateFiles:
          getOrgData?.organization.taskSharedCanCreateFiles,
        taskSharedCanCreateFolders:
          getOrgData?.organization.taskSharedCanCreateFolders,
        name: getOrgData?.organization.name,
        invoiceEmail: getOrgData?.organization.invoiceEmail,
        organizationStageId: getOrgData?.organization.organizationStage?.id,
        organizationStageDescription:
          getOrgData?.organization.organizationStage?.name,
        accountManagerSearch:
          getOrgData?.organization.accountManager?.gaiaUser.fullName,
        accountManager: getOrgData?.organization.accountManager?.id,
        csvSubjects: [],
        metadata: JSON.parse(getOrgData.organization.metadata),
        paidPackagesIds: [],
        category: getOrgData?.organization?.category
          ? transformCategory(getOrgData?.organization?.category)
          : null,
        locations: transformedLocations,
        contacts: transformedContacts,
        sessionPackageBackgrounds: transformedSessionPackageBackgrounds,
        notes: getOrgData?.organization.notes
          ? getOrgData?.organization.notes
          : '',
        bankAccounts: bankAccounts,
        logoImage: '',
        domains: [],
        group: [],
        refundPolicy: getOrgData?.organization.refundPolicy,
        resitFee: getOrgData?.organization.resitFee,
        applyNoShowPolicyFree: getOrgData?.organization.applyNoShowPolicyFree,
        applyNoShowPolicyPaid: getOrgData?.organization.applyNoShowPolicyPaid,
        applyPolicyFree: getOrgData?.organization.applyPolicyFree,
        applyPolicyPaid: getOrgData?.organization.applyPolicyPaid,
        timeRefundHours: getOrgData?.organization.timeRefundHours,
        timeRefundFee: getOrgData?.organization.timeRefundFee,
        resitFeeFreeSessions: getOrgData?.organization.resitFeeFreeSessions,
        resitsIncludeFreeSessions:
          getOrgData?.organization.resitsIncludeFreeSessions,
        timeRefundSessionPackageCost:
          getOrgData?.organization.timeRefundSessionPackageCost,
        noShowFee: getOrgData?.organization.noShowFee,
        freePackageResitFee: getOrgData?.organization.freePackageResitFee,
        usingNoShowPolicy: getOrgData?.organization.usingNoShowPolicy,
        usingCancellationPolicy:
          getOrgData?.organization.usingCancellationPolicy,
        usingResitPolicy: getOrgData?.organization.usingResitPolicy,
        resitFeePaidSessions: getOrgData?.organization.resitFeePaidSessions,
        tags: getOrgData?.organization?.tags?.map((tag) => {
          return {
            description: tag.name,
            id: tag.id,
          }
        }),
      }
    : {
        tags: [],
        paidPackagesIds: [],
        name: '',
        notificationCopyOrg: {
          name: '',
          id: '',
        },
        sharedCanSeeFiles: folderConfiguration?.organizationSharedCanSeeFiles,
        sharedCanCreateFiles:
          folderConfiguration?.organizationSharedCanCreateFiles,
        sharedCanCreateFolders:
          folderConfiguration?.organizationSharedCanCreateFolders,
        subjectSharedCanSeeFiles: folderConfiguration?.subjectSharedCanSeeFiles,
        subjectSharedCanCreateFiles:
          folderConfiguration?.subjectSharedCanCreateFiles,
        subjectSharedCanCreateFolders:
          folderConfiguration?.subjectSharedCanCreateFolders,
        subjectGroupSharedCanSeeFiles:
          folderConfiguration?.subjectGroupSharedCanSeeFiles,
        subjectGroupSharedCanCreateFiles:
          folderConfiguration?.subjectGroupSharedCanCreateFiles,
        subjectGroupSharedCanCreateFolders:
          folderConfiguration?.subjectGroupSharedCanCreateFolders,
        taskSharedCanSeeFiles: folderConfiguration?.taskSharedCanSeeFiles,
        taskSharedCanCreateFiles: folderConfiguration?.taskSharedCanCreateFiles,
        taskSharedCanCreateFolders:
          folderConfiguration?.taskSharedCanCreateFolders,
        metadata: {},
        accountManager: '',
        category: 'School',
        organizationStageId: '',
        organizationStageName: '',
        locations: [],
        csvSubjects: [],
        resitFeeFreeSessions: true,
        resitFee: 0,
        notes: '',
        resitFeePaidSessions: true,
        contacts: [],
        sessionPackageBackgrounds: [],
        logoImage: '',
        domains: [],
        group: [],
        refundPolicy: 'TIME',
        applyNoShowPolicyFree: true,
        applyNoShowPolicyPaid: true,
        applyPolicyFree: true,
        applyPolicyPaid: true,
        timeRefundHours: 24,
        timeRefundFee: 0,
        resitsIncludeFreeSessions: true,
        timeRefundSessionPackageCost: true,
        noShowFee: 0,
        freePackageResitFee: 0,
        usingNoShowPolicy: 'SETTINGS',
        usingCancellationPolicy: 'SETTINGS',
        usingResitPolicy: 'SETTINGS',
      }

  async function getS3Object(Key) {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          setLogoImage(awsS3.encodeS3ImageData(data.Body))
        }
      }
    )
  }

  if ((orgId && !getOrgData) || !awsS3?.client || !folderConfiguration) {
    return (
      <Modal
        show={showOrgModal}
        onHide={innerOnHide}
        animation={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Buildings className="mr-2" />
            {updatingOrg ? (
              <span>Edit Organization</span>
            ) : (
              <span>New Organization</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading message="Loading Organization..." />
        </Modal.Body>
      </Modal>
    )
  }
  return (
    <Formik
      initialValues={orgInitialValues}
      validationSchema={Yup.object().shape({
        organizationStageDescription: Yup.string().nullable(),
        organizationStageId: Yup.string().nullable(),
        sharedCanSeeFiles: Yup.boolean().required('Required'),
        sharedCanCreateFiles: Yup.boolean().required('Required'),
        sharedCanCreateFolders: Yup.boolean().required('Required'),
        subjectSharedCanSeeFiles: Yup.boolean().required('Required'),
        subjectSharedCanCreateFiles: Yup.boolean().required('Required'),
        subjectSharedCanCreateFolders: Yup.boolean().required('Required'),
        subjectGroupSharedCanSeeFiles: Yup.boolean().required('Required'),
        subjectGroupSharedCanCreateFiles: Yup.boolean().required('Required'),
        subjectGroupSharedCanCreateFolders: Yup.boolean().required('Required'),
        taskSharedCanSeeFiles: Yup.boolean().required('Required'),
        taskSharedCanCreateFiles: Yup.boolean().required('Required'),
        taskSharedCanCreateFolders: Yup.boolean().required('Required'),
        name: Yup.string()
          .required('Name required')
          .test(
            'isUnique',
            'Another organization exists with this name',
            async (value, context) => {
              let valid = true
              if (value) {
                const { data } = await client.query({
                  query: gql`
                    query OrganizationName($name: String) {
                      organizations(name_Iexact: $name) {
                        nodeCount
                        edges {
                          node {
                            id
                          }
                        }
                      }
                    }
                  `,
                  fetchPolicy: 'no-cache',
                  variables: {
                    name: value,
                  },
                })
                if (!updatingOrg && data.organizations.nodeCount > 0) {
                  valid = false
                } else if (
                  updatingOrg &&
                  data.organizations.nodeCount > 0 &&
                  data.organizations.edges[0].node.id != orgId
                ) {
                  valid = false
                }
              }
              return valid
            }
          ),
        domains: Yup.array()
          .of(Yup.string().required('Provide value or remove'))
          .test('isUniqueDomain', 'Domains must be unique', async (value) => {
            if (value.length > 0) {
              const resolvedPromises = await Promise.all(
                value.map(async (val) => {
                  const { data } = await client.query({
                    query: gql`
                      query Domains($nameIexact: String) {
                        domains(name_Iexact: $nameIexact) {
                          edges {
                            node {
                              id
                              name
                              organization {
                                id
                              }
                            }
                          }
                          nodeCount
                        }
                      }
                    `,
                    variables: {
                      nameIexact: val,
                    },
                    fetchPolicy: 'network-only',
                  })
                  if (
                    !updatingOrg &&
                    data.domains &&
                    data.domains.nodeCount > 0
                  ) {
                    return true
                  } else if (
                    updatingOrg &&
                    data.domains &&
                    data.domains.nodeCount > 0
                  ) {
                    let result = false
                    data.domains.edges.forEach((d) => {
                      if (d.node.organization.id !== orgId) {
                        result = true
                      }
                    })
                    return result
                  } else {
                    return false
                  }
                })
              )
              if (resolvedPromises.some((el) => el)) {
                return false
              }
            }
            return true
          }),
        accountManager: Yup.string(),
        notificationCopyOrg: Yup.object().shape({
          id: Yup.string().nullable(),
          name: Yup.string().nullable(),
        }),
        plaidPublicToken: Yup.string().nullable(),
        plaidMetadata: Yup.object().nullable(),
        refundPolicy: Yup.string().nullable(),
        applyNoShowPolicyFree: Yup.boolean().nullable(),
        applyNoShowPolicyPaid: Yup.boolean().nullable(),
        applyPolicyFree: Yup.boolean().nullable(),
        resitFeePaidSessions: Yup.boolean().nullable(),
        applyPolicyPaid: Yup.boolean().nullable(),
        timeRefundHours: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingCancellationPolicy === 'ORGANIZATION' &&
              context.parent.refundPolicy === 'TIME' &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        timeRefundFee: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingCancellationPolicy === 'ORGANIZATION' &&
              context.parent.refundPolicy === 'TIME' &&
              !(typeof value === 'number')
            ) {
              valid = false
            } else if (
              context.parent.usingCancellationPolicy === 'ORGANIZATION' &&
              context.parent.refundPolicy === 'NEVER' &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        timeRefundSessionPackageCost: Yup.boolean().nullable(),
        resitsIncludeFreeSessions: Yup.boolean().nullable(),
        noShowFee: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingNoShowPolicy === 'ORGANIZATION' &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        resitFeeFreeSessions: Yup.boolean().nullable(),
        resitFee: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingResitPolicy === 'ORGANIZATION' &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        freePackageResitFee: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingResitPolicy === 'ORGANIZATION' &&
              context.parent.resitsIncludeFreeSessions &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        usingNoShowPolicy: Yup.string().nullable(),
        usingCancellationPolicy: Yup.string().nullable(),
        usingResitPolicy: Yup.string().nullable(),
        csvSubjects: Yup.array().of(
          Yup.object().shape({
            subjectFirstName: Yup.string().nullable(),
            subjectLastName: Yup.string().nullable(),
            subjectPhone: Yup.string().nullable(),
            subjectEmail: Yup.string().nullable(),
            parentOneFirstName: Yup.string().nullable(),
            parentOneLastName: Yup.string().nullable(),
            parentOnePhone: Yup.string().nullable(),
            parentOneSecondaryPhone: Yup.string().nullable(),
            parentOneEmail: Yup.string().nullable(),
            parentOneSecondaryEmail: Yup.string().nullable(),
            parentTwoFirstName: Yup.string().nullable(),
            parentTwoLastName: Yup.string().nullable(),
            parentTwoPhone: Yup.string().nullable(),
            parentTwoEmail: Yup.string().nullable(),
            addressLineOne: Yup.string().nullable(),
            addressLineTwo: Yup.string().nullable(),
            city: Yup.string().nullable(),
            zipCode: Yup.string().nullable(),
            state: Yup.string().nullable(),
            studentId: Yup.string().nullable(),
          })
        ),
        paidPackagesIds: Yup.array().of(Yup.string()),
        category: Yup.string(),
        locations: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string(),
              addressLineOne: Yup.string(),
              addressLineTwo: Yup.string().nullable(),
              city: Yup.string(),
              zipCode: Yup.string(),
              state: Yup.string(), // removed required property for now because sometimes google autocomplete doesn't share complete details
              shippingAddress: Yup.bool(),
              billingAddress: Yup.bool(),
            })
          )
          .test(
            'shipBillCheck',
            'Only One shipping address and one billing address across all locations is allowed',
            (value) => {
              let billCount = 0
              let shipCount = 0
              value.forEach((address) => {
                if (address.shippingAddress) {
                  shipCount += 1
                }
                if (address.billingAddress) {
                  billCount += 1
                }
              })
              return billCount <= 1 && shipCount <= 1
            }
          ),
        invoiceEmail: Yup.string()
          .nullable()
          .test('isEmail', 'Invalid email', (value) => {
            let valid = true
            if (value && !validator.isEmail(value)) {
              valid = false
            }
            return valid
          }),
        notes: Yup.string(),
        sessionPackageBackgrounds: Yup.array().of(
          Yup.object().shape({
            sessionPackageId: Yup.string().required('required'),
            backgroundColor: Yup.string().required('required'),
            id: Yup.string().nullable(),
          })
        ),
        contacts: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            firstName: Yup.string().required('First Name is required'),
            jobTitle: Yup.string().nullable(),
            lastName: Yup.string().required('Last Name is required'),
            email: Yup.string()
              .required('Email required')
              .test('isEmail', 'Invalid email', (value) => {
                let valid = true
                if (value && !validator.isEmail(value)) {
                  valid = false
                }
                return valid
              }),
            phoneNumber: Yup.string().nullable(),
            secondaryEmail: Yup.string().nullable(),
            secondaryPhoneNumber: Yup.string().nullable(),
            password: Yup.string().nullable(),
            confirmPassword: Yup.string()
              .nullable()
              .oneOf([Yup.ref('password')], 'Passwords must match'),
            notes: Yup.string().nullable(),
            emailNotificationsEnabled: Yup.bool(),
            smsNotificationsEnabled: Yup.bool(),
          })
        ),
        tags: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().nullable(),
            description: Yup.string().nullable(),
          })
        ),
      })}
      onSubmit={(values) => {
        setIsSubmitting(true)
        const newContacts = produce(values.contacts, (draftState) => {
          draftState.forEach((contact) => {
            if (contact.id && contact.password == '') {
              contact.password = null
            }
            delete contact.confirmPassword
            delete contact.id
          })
        })

        const sessionPackageBackgrounds = produce(
          values.sessionPackageBackgrounds,
          (draftState) => {
            draftState.forEach((sessionPackageBackground) => {
              delete sessionPackageBackground.title
              if (!updatingOrg) {
                delete sessionPackageBackground.id
              }
            })
          }
        )
        let category = transformCategory(values.category)
        let csvSubjectsLen = false
        if (values.csvSubjects && values.csvSubjects.length > 0) {
          csvSubjectsLen = values.csvSubjects.length
          setCsvSubjects(values.csvSubjects)
        }
        const addTaskCollectionIds = values.group.map((groupData) => {
          return groupData.node.id
        })
        const tags = values.tags.map((tag) => {
          return tag.description
        })
        if (!updatingOrg) {
          const organizationInput = {
            contacts: newContacts,
            metadata: JSON.stringify(values.metadata),
            locations: values.locations.map((l) => {
              delete l.isSelected
              return l
            }),
            plaidPublicToken: values.plaidPublicToken,
            invoiceEmail: values.invoiceEmail,
            refundPolicy: values.refundPolicy,
            applyNoShowPolicyFree: values.applyNoShowPolicyFree,
            applyNoShowPolicyPaid: values.applyNoShowPolicyPaid,
            applyPolicyFree: values.applyPolicyFree,
            applyPolicyPaid: values.applyPolicyPaid,
            timeRefundHours: values.timeRefundHours,
            timeRefundFee: values.timeRefundFee,
            organizationStageId: values.organizationStageId,
            resitsIncludeFreeSessions: values.resitsIncludeFreeSessions,
            timeRefundSessionPackageCost: values.timeRefundSessionPackageCost,
            noShowFee: values.noShowFee,
            resitFee: values.resitFee,
            sharedCanSeeFiles: values.sharedCanSeeFiles,
            sharedCanCreateFiles: values.sharedCanCreateFiles,
            sharedCanCreateFolders: values.sharedCanCreateFolders,
            subjectSharedCanSeeFiles: values.subjectSharedCanSeeFiles,
            subjectSharedCanCreateFiles: values.subjectSharedCanCreateFiles,
            subjectSharedCanCreateFolders: values.subjectSharedCanCreateFolders,
            subjectGroupSharedCanSeeFiles: values.subjectGroupSharedCanSeeFiles,
            subjectGroupSharedCanCreateFiles:
              values.subjectGroupSharedCanCreateFiles,
            subjectGroupSharedCanCreateFolders:
              values.subjectGroupSharedCanCreateFolders,
            taskSharedCanSeeFiles: values.taskSharedCanSeeFiles,
            taskSharedCanCreateFiles: values.taskSharedCanCreateFiles,
            taskSharedCanCreateFolders: values.taskSharedCanCreateFolders,
            usingNoShowPolicy: values.usingNoShowPolicy,
            usingCancellationPolicy: values.usingCancellationPolicy,
            usingResitPolicy: values.usingResitPolicy,
            resitFeePaidSessions: values.resitFeePaidSessions,
            notificationCopyOrganizationId: values.notificationCopyOrg.id,
            csvSubjects: csvSubjectsLen ? csvSubjectsLen : null,
            name: values.name,
            resitFeeFreeSessions: values.resitFeeFreeSessions,
            category: category,
            paidPackagesIds: values.paidPackagesIds,
            sessionPackageBackgrounds,
            domains: values.domains,
            accountManagerId: values.accountManager,
            notes: values.notes,
            taskCollectionIds: addTaskCollectionIds,
            tags,
          }
          if (values.logoImage) {
            organizationInput.logoImage = {
              image: values.logoImage[0].file,
              nameOnUpload: values.logoImage[0].filename,
            }
          }
          createOrg({
            variables: {
              createOrganizationInput: {
                organizationInput,
              },
            },
          })
        } else {
          const organizationInput = {
            id: orgId,
            metadata: JSON.stringify(values.metadata),
            name: values.name,
            category: category,
            plaidPublicToken: values.plaidPublicToken,
            csvSubjects: csvSubjectsLen ? csvSubjectsLen : null,
            notes: values.notes,
            sharedCanSeeFiles: values.sharedCanSeeFiles,
            sharedCanCreateFiles: values.sharedCanCreateFiles,
            sharedCanCreateFolders: values.sharedCanCreateFolders,
            subjectSharedCanSeeFiles: values.subjectSharedCanSeeFiles,
            subjectSharedCanCreateFiles: values.subjectSharedCanCreateFiles,
            subjectSharedCanCreateFolders: values.subjectSharedCanCreateFolders,
            subjectGroupSharedCanSeeFiles: values.subjectGroupSharedCanSeeFiles,
            subjectGroupSharedCanCreateFiles:
              values.subjectGroupSharedCanCreateFiles,
            subjectGroupSharedCanCreateFolders:
              values.subjectGroupSharedCanCreateFolders,
            taskSharedCanSeeFiles: values.taskSharedCanSeeFiles,
            taskSharedCanCreateFiles: values.taskSharedCanCreateFiles,
            taskSharedCanCreateFolders: values.taskSharedCanCreateFolders,
            resitFee: values.resitFee,
            invoiceEmail: values.invoiceEmail,
            refundPolicy: values.refundPolicy,
            organizationStageId: values.organizationStageId,
            applyNoShowPolicyFree: values.applyNoShowPolicyFree,
            applyNoShowPolicyPaid: values.applyNoShowPolicyPaid,
            applyPolicyFree: values.applyPolicyFree,
            applyPolicyPaid: values.applyPolicyPaid,
            timeRefundHours: values.timeRefundHours,
            timeRefundFee: values.timeRefundFee,
            resitFeePaidSessions: values.resitFeePaidSessions,
            freePackageResitFee: values.freePackageResitFee,
            resitsIncludeFreeSessions: values.resitsIncludeFreeSessions,
            timeRefundSessionPackageCost: values.timeRefundSessionPackageCost,
            noShowFee: values.noShowFee,
            resitFeeFreeSessions: values.resitFeeFreeSessions,
            usingNoShowPolicy: values.usingNoShowPolicy,
            usingCancellationPolicy: values.usingCancellationPolicy,
            usingResitPolicy: values.usingResitPolicy,
            contacts: newContacts,
            paidPackagesIds: values.paidPackagesIds,
            locations: values.locations.map((l) => {
              delete l.isSelected
              return l
            }),
            domains: values.domains,
            sessionPackageBackgrounds,
            accountManagerId: values.accountManager,
            taskCollectionIds: addTaskCollectionIds,
            tags,
          }
          if (values.logoImage) {
            organizationInput.logoImage = {
              image: values.logoImage[0].file,
              nameOnUpload: values.logoImage[0].filename,
            }
          }
          updateOrganization({
            variables: {
              updateOrganizationInput: {
                organizationInput,
              },
            },
          })
        }
      }}
    >
      {(formik) => (
        <Modal
          show={showOrgModal}
          onHide={() => {
            innerOnHide()
            formik.resetForm()
          }}
          animation={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Buildings className="mr-2" />
              {updatingOrg ? (
                <span>Edit Organization</span>
              ) : (
                <span> New Organization</span>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OrgForm
              setIsSubmitting={setIsSubmitting}
              showModal={showOrgModal}
              formik={formik}
              setCsvRecordSync={setCsvRecordSync}
              orgError={orgError}
              isOrgCreated={isOrgCreated}
              orgModalShowChange={orgModalShowChange}
              updatingOrg={updatingOrg}
              logoImage={logoImage}
              isSubmitting={isSubmitting}
              organizationId={getOrgData?.organization?.id}
              bankAccounts={bankAccounts}
              setBankAccounts={setBankAccounts}
            />
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  )
}
